import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
  Navigate,
} from "react-router-dom";
import Clock from "./View/Clock";
import Confirmation from "./View/Confirmation";
import Finalpage from "./View/Finalpage";
import GoogleApi from "./View/GoogleApi";
import GoogleApiPF from "./View/GoogleApiPf";
import TicketConfirmation from "./View/TicketConfirmation";
import PaytmPayment from "./View/paytm/PaytmPayment";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/google/flights" element={<GoogleApi />} />
        <Route path="/google/flights/:pf" element={<GoogleApiPF />} />
        <Route path="/bookandreview" element={<Finalpage />} />
        <Route path="/clock" element={<Clock />} />
        <Route
          exact
          path="/flight/ticketconfirmation/:Bookingid"
          element={<TicketConfirmation />}
        />
        <Route exact path="/confirmation" element={<Confirmation />} />
        <Route exact path="/paytm/paytmpayment" element={<PaytmPayment />} />
      </Routes>
    </>
  );
};

export default App;


