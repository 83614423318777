export const content = (payment, booking) => {
  const data =
    payment.toUpperCase() == "SUCCESS"
      ? booking.toUpperCase() == "SUCCESS"
        ? `Your booking is Ticketed,
   Please use below reference id for any further communication with us. +91-1204814491. Your booking reference number is`
        : `Your payment is confirmed and ticket is under process.Our customer service will inform you about the status. The airline did not give a clear indication of the status of this booking. If the booking succeeded, you will receive an email confirmation from them shortly.Please do not re-book the booking until our customer service team would not informed you about current status. If in doubt, please contact our help desk at +91-1204814491 . Your booking reference number is `
      : `Your booking is not confirmed due to payment failure.Our customer service will inform you about the status.Please do not re-book the booking until our customer service team would not informed you about current status. If in doubt, please contact our help desk at +91-1204814491 . Your payment is failed from bank, Please contact your bank. your booking is pending until payment is not clear from the bank,please get in touch with us on +91-1204814491. Your booking reference number is `;
  return data;
};
