import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";

const PaytmPayment = () => {
  const location = useLocation();
  useEffect(() => {
    const script = document.createElement("script");
    script = document.paytm.submit();
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <center>
        <h1>Please do not refresh this page...</h1>
      </center>
      <form
        method="post"
        action={`https://securegw.paytm.in/theia/api/v1/showPaymentPage?mid=nKCSFy11719610044430&orderId=${location.state.orderId}`}
        name="paytm"
      >
        <table border="1">
          <tbody>
            <input type="hidden" name="mid" value={location.state.mid} />
            <input
              type="hidden"
              name="orderId"
              value={location.state.orderId}
            />
            <input
              type="hidden"
              name="txnToken"
              value={location.state.txtToken}
            />
          </tbody>
        </table>
        {/* <script type="text/javascript"> 
         {document.paytm.submit()}
          </script> */}
      </form>
    </>
  );
};

export default PaytmPayment;
