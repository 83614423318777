import React from "react";
import logo from "../../Image/logo.png";
import Lottie from "react-lottie";
import Details from "../../Animation/97203-loader.json";

const ConfirmationHeader = (props) => {
  const { bookingdata } = props;
  const details = {
    loop: true,
    autoplay: true,
    animationData: Details,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="headerconfirmation">
      <div className="container ">
        <div className="row p-4">
          <div className="col-6 col-md-4">
            <img src={logo} />
          </div>
          <div className="col-6 col-md-4">
            <p className="text-center text-xs md:text-sm lg:text-lg text-blue-500">
              <i class="fas fa-headset"></i> Customer Service (24/7) :{" "}
              <br className="block" />
              <b>91-1204814491</b>
            </p>
          </div>
          <div className="col-12 col-md-4 text-center md:text-right mt-4 md:mt-0">
            {bookingdata.passengerDetails[0].ticketDetails != null &&
            bookingdata.passengerDetails[0].ticketDetails.length != 0 ? (
              <>
                {" "}
                {bookingdata.bookingStatus == "SUCCESS" &&
                (bookingdata.tripType == "2"
                  ? bookingdata.passengerDetails[0].ticketDetails.length > 1
                    ? bookingdata.passengerDetails[0].ticketDetails[0].pnr !=
                        "" &&
                      bookingdata.passengerDetails[0].ticketDetails[1].pnr != ""
                    : bookingdata.passengerDetails[0].ticketDetails[0].pnr != ""
                  : bookingdata.passengerDetails[0].ticketDetails[0].pnr !=
                    "") ? (
                  <span className="text-white  font-bold bg-green-500 text-2xl p-2 rounded-lg">
                    {bookingdata.bookingStatus}
                  </span>
                ) : (
                  <>
                    <span className="text-white  font-bold bg-orange-500 text-2xl p-2 rounded-lg">
                      PENDING
                    </span>
                    {/* <span className="text-white  font-bold bg-green-500 text-2xl p-2 rounded-lg">{bookingdata.bookingStatus}</span> */}
                  </>
                )}
              </>
            ) : (
              ""
            )}
            {bookingdata.bookingStatus == "PENDING" &&
            bookingdata.paymentStatus == "SUCCESS" ? (
              <span className="text-white  font-bold bg-orange-500 text-2xl p-2 rounded-lg">
                {bookingdata.bookingStatus}
              </span>
            ) : (
              ""
            )}
            {bookingdata.bookingStatus == "PENDING" &&
            bookingdata.paymentStatus == "PENDING" ? (
              <span className="text-white  font-bold bg-red-500 text-2xl p-2 rounded-lg">
                FAILURE
              </span>
            ) : (
              ""
            )}
            {/* {bookingdata.bookingStatus == "SUCCESS" &&
            bookingdata.paymentStatus == "SUCCESS" ? (
              <span className="text-white  font-bold bg-green-500 text-2xl p-2 rounded-lg">
                SUCCESS
              </span>
            ) : (
              ""
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationHeader;
