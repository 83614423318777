import React from "react";
import logo from "../../Image/logo.png";

const ConfirmationFooter = () => {
  return (
    // <div className="footerConfirm">
    //   <div className="container ">
    //     <div className="grid grid-cols-12 p-4">
    //       <div className="col-span-3 "></div>
    //       <div className="col-span-6">
    //         <p className="text-center  thanks">Thank you for choosing</p>
    //         <div className="confirmFooterImage">
    //           <img src={logo} width="50%" />
    //         </div>
    //         <div className="footerConfirmation">
    //           <p className="text-primary">
    //             Terms and Conditions Privacy Policy
    //           </p>
    //           <p>Copyright Ⓒ 2018 Travomint. All Rights Reserved.</p>
    //           <p>For Assistance, Please Contact Travomint</p>
    //           <p>Via telephone : 91-1204814491 </p>
    //           <p>
    //             or Via E-Mail : <b className="text-primary">cs@Travomint.com</b>{" "}
    //             24x7.
    //           </p>
    //         </div>
    //       </div>
    //       <div className="col-span-3"></div>
    //     </div>
    //   </div>

    // </div>

    <div className="container-fluid text-white  bg-gray-800 mt-10">
      <div className="grid grid-cols-1 lg:grid-cols-12 lg:px-60 py-4">
        <div className="col-span-4">
          <img src={logo} width="50%" />
        </div>
        <div className="col-span-4 ">
          <div className="footerConfirmation text-xs">
            <p className="detail-user">
              Copyright Ⓒ {new Date().getFullYear()} Travomint. All Rights Reserved.
            </p>
          </div>
        </div>
        <div className="col-span-4  detail-user">
          <p>For Assistance, Please Contact Travomint</p>
          <p>Via telephone : 91-1204814491 </p>
          <p>
            or Via E-Mail : <b className="text-primary">cs@Travomint.com</b>{" "}
            24x7.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationFooter;
