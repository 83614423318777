import React from "react";


const Terms = () => {
    return (
        <div className="container mt-4">
            <div className="row py-4">

            <div className="col-md-12">
                <h3 className="text-primary text-center font-bold">Flight Booking Terms & Conditions </h3>
                <hr/>
                <p className="terms text-black detail-user3 ">* This is a booking acknowledgment and not the e ticket. The tickets will be issued shortly and sent to you on a separate email. Please contact us if you do not receive your e tickets within 24 hours.
<br/>
* The passengers assume all responsibilities of procuring travel related documents including passport (minimum 6 months validity from trip completion), VISAs, medical documents, etc.
<br/>
* Name changes are not permitted once the booking is confirmed. Minor corrections to the spellings may be allowed in some exceptional cases and such corrections will incur a penalty.
<br/>
* Prices do not include any additional fees charged by the airlines for services like baggage, seats, etc.
<br/>
* Fares are not guaranteed until ticketed. Fares are subject to change as per seat or class availability.
<br/>
* The tickets are non-refundable and non-transferable/ endorsable.
<br/>
* Changes to the itinerary are subject to airline fare rules.
<br/>
* Convenience Fee is non-refundable.
<br/>
* The flights must be used in the booked sequence. If any flight is unused/missed, all the subsequent flights will be cancelled and the amount paid towards such ticket(s) will be forfeited.
<br/>
* Web Check-in is Mandatory (opens 48 hrs. before departure): - Use PNR and last name only.</p>
<p className=" recommend mt-4 text-black detail-user">* We recommend to use the traveler's credit card, or else the booking will be considered a third party booking and in such cases the credit card will not be charged until the verification process is completed and you may receive a call to complete the same.
    </p>          

            </div>

</div>

        </div>
    )
}

export default Terms