import React, { useEffect } from "react";
import ConfirmationFooter from "../Atom/FlightConfirm/confimationFooter";
import ConfirmationHeader from "../Atom/FlightConfirm/ConfirmationHeader";
import Terms from "../Atom/FlightConfirm/Terms";
import { useLocation, useParams } from "react-router";
import { useState } from "react";
import AirportData from "../Sample_Data/AirPortData.json";
import moment from "moment";
import { Button } from "react-bootstrap";
import logo from "../Image/logo.svg";
import Flight_Not_Found from "../Image/Flight_Not_Found.png";
import { googlrBaseUrl, mailBaseUrl, websiteUrl } from "../static/static";
import { content } from "../utils/content";
import axios from "axios";

const Confirmation = (props) => {
  const orderID = useLocation().state;

  window.onpopstate = function (event) {
    if (event) {
      window.location.href = "https://www.google.com/travel/flights";
      // Code to handle back button or prevent from navigation
    }
  };

  const [status, setStatus] = useState({});
  const [load, setload] = useState(false);
  const [firstcheck, setFirstCheck] = useState(false);
  const [check, setCheck] = useState(false);
  const [loader, setLoader] = useState(false);

  function checkStatus() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: orderID.Bookingid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${googlrBaseUrl}/confirmation`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setStatus(result);
        setload(true);
        setFirstCheck(true);
        setCheck(true);
        setLoader(true);
      })
      .catch((error) => {});
  }

  const ConvertMinsToTime = ({ data }) => {
    let hours = Math.floor(data / 60);
    let minutes = data % 60;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}h:${minutes}m`;
  };
  const convertFrom24To12Format = (time24) => {
    const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    const period = +sHours < 12 ? "AM" : "PM";
    const hours = +sHours % 12 || 12;

    return `${hours}:${minutes} ${period}`;
  };
  const handleMailConfirmation = async () => {
    const isMail = JSON?.parse(sessionStorage.getItem("is_Ml"));
    if (orderID.Bookingid && isMail) {
      try {
        const { data } = await axios.post(
          `${mailBaseUrl}/api/send-confirmationmail?api_token=0uuZqgE8RXJ2bbkmA1BZSUc02nQzFcsjViLT1ZA9mUwtaokQVZtSqu4UxBAe`,
          {
            bookingId: orderID.Bookingid,
            source: "Google",
          }
        );
        sessionStorage.setItem("is_Ml", false);
      } catch (error) {}
    }
  };
  useEffect(() => {
    checkStatus();
    handleMailConfirmation();
  }, []);

  return (
    <>
      <div>
        <div>
          {load ? (
            <div>
              {/* __________________________________ DETAIL_____________________________________ */}
              {status.httpStatus === "OK" ? (
                <>
                  {status.data != null ? (
                    <>
                      <>
                        <div>
                          <ConfirmationHeader bookingdata={status.data} />
                          <div className="container confirmationpage ">
                            {/* ---------------------------------------PNR DETAIL AND status ---------------------------------------*/}
                            <div className="container PNRStatus">
                              <div className=" text-center">
                                <div>
                                  <div className="PNRstatus_top mb-2">
                                    PNR NO.
                                    <span className="pnr_NO text-primary lg:text-xl font-bold">
                                      {status.data.passengerDetails != null &&
                                      status.data.passengerDetails[0]
                                        .ticketDetails != null ? (
                                        <>
                                          {status.data.tripType == "2" ? (
                                            <>
                                              {status.data.passengerDetails[0]
                                                .ticketDetails.length > 1 ? (
                                                status.data.passengerDetails[0]
                                                  .ticketDetails[0].pnr != "" &&
                                                status.data.passengerDetails[0]
                                                  .ticketDetails[1].pnr !=
                                                  "" ? (
                                                  <>
                                                    <span>
                                                      {status.data.passengerDetails[0].ticketDetails.map(
                                                        (items, i) =>
                                                          i > 0
                                                            ? status.data
                                                                .passengerDetails[0]
                                                                .ticketDetails[
                                                                i - 1
                                                              ].pnr ===
                                                              items.pnr
                                                              ? ""
                                                              : " / " +
                                                                items.pnr
                                                            : items.pnr
                                                      )}
                                                    </span>
                                                  </>
                                                ) : (
                                                  <>X</>
                                                )
                                              ) : status.data
                                                  .passengerDetails[0]
                                                  .ticketDetails[0].pnr !=
                                                "" ? (
                                                <>
                                                  <span>
                                                    {status.data.passengerDetails[0].ticketDetails.map(
                                                      (items, i) =>
                                                        i > 0
                                                          ? status.data
                                                              .passengerDetails[0]
                                                              .ticketDetails[
                                                              i - 1
                                                            ].pnr === items.pnr
                                                            ? ""
                                                            : " / " + items.pnr
                                                          : items.pnr
                                                    )}
                                                  </span>
                                                </>
                                              ) : (
                                                <>X</>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {status.data.passengerDetails[0]
                                                .ticketDetails[0].pnr != "" ? (
                                                <>
                                                  <span>
                                                    {status.data.passengerDetails[0].ticketDetails.map(
                                                      (items, i) =>
                                                        i > 0
                                                          ? status.data
                                                              .passengerDetails[0]
                                                              .ticketDetails[
                                                              i - 1
                                                            ].pnr === items.pnr
                                                            ? ""
                                                            : " / " + items.pnr
                                                          : items.pnr
                                                    )}
                                                  </span>
                                                </>
                                              ) : (
                                                <>X</>
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <span>X</span>
                                      )}
                                    </span>
                                  </div>
                                </div>

                                <div className="status-head">
                                  {content(
                                    status.data.paymentStatus,
                                    status.data.bookingStatus
                                  )}{" "}
                                  {orderID.Bookingid}
                                  {/* --------------------------------BOOKING SUCCESS AND PNR GENERAT AND PAYMENT SUCCESS  ------------------------------ */}
                                  {/* --------------------------------BOOKING SUCCESS AND PNR GENERAT AND PAYMENT SUCCESS  ------------------------------ */}
                                </div>
                              </div>
                            </div>
                            {/* ---------------------------------------PNR DETAIL AND status ---------------------------------------*/}

                            {/*----------------------------------------- BOKING DETAILS---------------------------------------- */}
                            <div className="container confirmation-bookingDetails">
                              <div className="row">
                                <div className="col-md-4 col-6 text-xs md:text-sm ">
                                  <p>
                                    Booking Reference No :{" "}
                                    <b>{orderID.Bookingid}</b>
                                  </p>
                                  <p>
                                    Date Booked :{" "}
                                    <b>
                                      {moment(status.data.creationDate).format(
                                        "dddd, DD-MMMM-yyyy"
                                      )}{" "}
                                      ,
                                      {moment(status.data.creationDate).format(
                                        "LT"
                                      )}{" "}
                                    </b>{" "}
                                  </p>
                                  <p>
                                    Departure :{" "}
                                    <b>
                                      {moment(
                                        status.data.supplierFlight.onWordFlight
                                          .outBound[0].depDate
                                      ).format("dddd, DD-MMMM-yyyy")}{" "}
                                      ,
                                      {moment(
                                        status.data.supplierFlight.onWordFlight
                                          .outBound[0].depDate
                                      ).format("LT")}
                                    </b>{" "}
                                  </p>
                                </div>
                                <div className="col-md-4 col-6 text-xs md:text-sm ">
                                  <p>
                                    {" "}
                                    Origin :{" "}
                                    <b>
                                      {" "}
                                      {status.data.passengerDetails != null ? (
                                        <span>
                                          {
                                            status.data.supplierFlight
                                              .onWordFlight.outBound[0]
                                              .fromAirport
                                          }
                                          ,
                                          {AirportData.filter(
                                            (items) =>
                                              items.airportCode ==
                                              status.data.supplierFlight
                                                .onWordFlight.outBound[0]
                                                .fromAirport
                                          ).map(
                                            (items, i) => items.airportName
                                          )}
                                        </span>
                                      ) : (
                                        <span></span>
                                      )}{" "}
                                    </b>{" "}
                                  </p>
                                  <p>
                                    {" "}
                                    Destination :{" "}
                                    <b>
                                      {" "}
                                      {status.data.passengerDetails != null ? (
                                        <span>
                                          {
                                            status.data.supplierFlight
                                              .onWordFlight.outBound[0]
                                              .toAirport
                                          }
                                          ,
                                          {AirportData.filter(
                                            (items) =>
                                              items.airportCode ==
                                              status.data.supplierFlight
                                                .onWordFlight.outBound[0]
                                                .toAirport
                                          ).map(
                                            (items, i) => items.airportName
                                          )}
                                        </span>
                                      ) : (
                                        <span></span>
                                      )}{" "}
                                    </b>{" "}
                                  </p>
                                  {status.data.tripType == 2 && (
                                    <p>
                                      Return :{" "}
                                      <b>
                                        {moment(
                                          status.data.supplierFlight
                                            .returnFlight.inBound[0].depDate
                                        ).format("dddd, DD-MMMM-yyyy")}
                                        ,
                                        {moment(
                                          status.data.supplierFlight
                                            .returnFlight.inBound[0].depDate
                                        ).format("LT")}
                                      </b>{" "}
                                    </p>
                                  )}
                                </div>
                                <div className="col-md-4 col-6 text-xs md:text-sm ">
                                  <p>
                                    {" "}
                                    Journey Type :{" "}
                                    <b>
                                      {status.data.tripType == 1
                                        ? "One Way"
                                        : "Round Trip"}
                                    </b>{" "}
                                  </p>
                                  <p>
                                    {" "}
                                    Passengers:{" "}
                                    <b>
                                      {status.data.passengerDetails != null
                                        ? status.data.passengerDetails.length
                                        : ""}
                                    </b>{" "}
                                  </p>
                                  <p>
                                    {" "}
                                    Total Price:{" "}
                                    <b>
                                      {" "}
                                      <i class="fas fa-rupee-sign  fa-sm  "></i>{" "}
                                      {(status.data.supplierFlight
                                        .returnFlight == null
                                        ? status.data.supplierFlight
                                            .onWordFlight.fare.baseFare +
                                          status.data.supplierFlight
                                            .onWordFlight.fare.totalMarkup +
                                          status.data.supplierFlight
                                            .onWordFlight.fare.totalTax +
                                          status.data.supplierFlight
                                            .onWordFlight.fare
                                            .AdditionalTxnFeeOfrd +
                                          status.data.supplierFlight
                                            .onWordFlight.fare.convenienceFees *
                                            status.data.passengerDetails.length
                                        : status.data.supplierFlight
                                            .onWordFlight.fare.baseFare +
                                          status.data.supplierFlight
                                            .onWordFlight.fare.totalMarkup +
                                          status.data.supplierFlight
                                            .onWordFlight.fare.totalTax +
                                          status.data.supplierFlight
                                            .onWordFlight.fare
                                            .AdditionalTxnFeeOfrd +
                                          status.data.supplierFlight
                                            .onWordFlight.fare.convenienceFees *
                                            status.data.passengerDetails
                                              .length +
                                          status.data.supplierFlight
                                            .returnFlight.fare.baseFare +
                                          status.data.supplierFlight
                                            .returnFlight.fare.totalMarkup +
                                          status.data.supplierFlight
                                            .returnFlight.fare.totalTax +
                                          status.data.supplierFlight
                                            .returnFlight.fare
                                            .AdditionalTxnFeeOfrd +
                                          status.data.supplierFlight
                                            .returnFlight.fare.convenienceFees *
                                            status.data.passengerDetails.length
                                      )?.toFixed(2)}
                                      {/* {status.data.supplierFlight.returnFlight==null?"hello":"check"} */}
                                    </b>{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/*----------------------------------------- BOKING DETAILS---------------------------------------- */}

                            {/*--------------------------------------------- flight details-------------------------------------- */}
                            <div className=" grid grid-cols-1 up flight-details-payment  mx-1 rounded-2xl ">
                              <div className="  w-full rounded-t-2xl text-left font-bold text-xl text-black text-white ">
                                <i class="fas fa-fighter-jet text-black -rotate-45 "></i>{" "}
                                &nbsp; Flight Details
                              </div>
                              <div>
                                <div className="px-4 py-1 md:py-0 user-info-table-heading">
                                  <h4 className="text-left">Departure</h4>
                                </div>
                                {status.data.supplierFlight.onWordFlight.outBound.map(
                                  (item, index) => {
                                    return (
                                      <div className="grid grid-cols-6 fareC  mt-2  px-2">
                                        <div className="col-span-6 md:col-span-2">
                                          <div className="grid grid-cols-3">
                                            <div className="pt-2 pb-3">
                                              <img
                                                src={`${websiteUrl}/resources/images/airline-logo/${item.airline}.png`}
                                                className="w-full lg:w-full down rounded-xl inline float-right"
                                              />
                                            </div>

                                            <div className=" py-10 col-span-2 ">
                                              <span className="text-xl text-black font-sans font-bold">
                                                {item.airlineName}
                                              </span>
                                              -
                                              <span className="text-xs  text-black font-sans font-bold">
                                                {" "}
                                                {item.flightNo}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-span-6 md:col-span-4  lg:px-14">
                                          <div className="grid grid-cols-6 fareC">
                                            <div className="text-left  py-3 md:col-span-2 col-span-6">
                                              <span className="  text-black font-bold">
                                                {item.fromAirport},{" "}
                                                {AirportData.filter(
                                                  (items) =>
                                                    items.airportCode ==
                                                    item.fromAirport
                                                ).map(
                                                  (items, i) =>
                                                    items.airportName
                                                )}
                                              </span>
                                              <br />
                                              <span className="  text-black font-bold">
                                                {/* {item.depDate.split("T")[0]}{" "} */}
                                                {moment(item.depDate).format(
                                                  "dddd, DD-MMMM-yyyy"
                                                )}{" "}
                                                <br />
                                                {moment(item.depDate).format(
                                                  "LT"
                                                )}
                                              </span>
                                            </div>
                                            <div className="col-span-6 md:col-span-2 text-center">
                                              <div className="grid grid-cols-9 mt-4 ">
                                                <div className="col-span-3 pl-2">
                                                  <hr className="bg-black opacity-100" />
                                                </div>
                                                <div className="col-span-3 text-center relative -top-2 pt-2">
                                                  <span className="detail-user text-black font-sans">
                                                    <ConvertMinsToTime
                                                      data={item.eft}
                                                    />
                                                  </span>
                                                  <br />
                                                  {/* <span className="text-xs text-black font-sans">
                            {flightdata.outBound.length === 1
                              ? "Non-Stop"
                              : flightdata.outBound.length === 2
                              ? "one-Stop"
                              : flightdata.outBound.length === 3
                              ? "two-stop"
                              : null}
                          </span> */}
                                                </div>
                                                <div className="col-span-3 pr-2">
                                                  <hr className="bg-black opacity-100" />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="text-right py-3 md:col-span-2 col-span-6">
                                              <span className=" text-black font-bold">
                                                {item.toAirport},{" "}
                                                {AirportData.filter(
                                                  (items) =>
                                                    items.airportCode ==
                                                    item.toAirport
                                                ).map(
                                                  (items, i) =>
                                                    items.airportName
                                                )}
                                              </span>
                                              <br />
                                              <span className=" text-black font-bold">
                                                {moment(item.reachDate).format(
                                                  "dddd, DD-MMMM-yyyy"
                                                )}{" "}
                                                <br />
                                                {moment(item.reachDate).format(
                                                  "LT"
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>

                              {status.data.tripType == 2 ? (
                                <>
                                  {status.data.domestic === false ? (
                                    <div>
                                      <div className="px-4 py-1 md:py-0 user-info-table-heading">
                                        <h4 className="text-left">Return</h4>
                                      </div>
                                      {status.data.supplierFlight.onWordFlight.inBound.map(
                                        (item, index) => {
                                          return (
                                            <div className="grid grid-cols-6  mt-2  ">
                                              <div className="col-span-2">
                                                <div className="grid grid-cols-3">
                                                  <div className="pt-2 pb-3">
                                                    <img
                                                      src={`${websiteUrl}/resources/images/airline-logo/${item.airline}.png`}
                                                      className="w-6/12 down rounded-xl inline float-right"
                                                    />
                                                  </div>

                                                  <div className="pl-4 pt-3 col-span-2 ">
                                                    <span className="text-xl text-black font-sans font-bold">
                                                      {item.airlineName}
                                                    </span>
                                                    -
                                                    <span className="text-xs  text-black font-sans font-bold">
                                                      {" "}
                                                      {item.flightNo}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-span-4 px-14">
                                                <div className="grid grid-cols-4">
                                                  <div className="text-center py-3">
                                                    <span className="text-lg text-black font-bold">
                                                      <span>
                                                        {
                                                          item.depDate.split(
                                                            "T"
                                                          )[0]
                                                        }
                                                      </span>
                                                      <br />
                                                    </span>
                                                    <span className="text-lg text-black font-bold">
                                                      {convertFrom24To12Format(
                                                        item.depDate
                                                          .split("T")[1]
                                                          .substring(0, 5)
                                                      )}{" "}
                                                    </span>
                                                    <br />
                                                    <span className="text-lg text-black font-bold">
                                                      {item.fromAirport}
                                                    </span>
                                                  </div>
                                                  <div className="col-span-2">
                                                    <div className="grid grid-cols-9 mt-3 ">
                                                      <div className="col-span-3 pl-2">
                                                        <hr className="bg-black opacity-100" />
                                                      </div>
                                                      <div className="col-span-3 text-center relative -top-2">
                                                        <span className="text-xs text-black font-sans">
                                                          <ConvertMinsToTime
                                                            data={item.eft}
                                                          />
                                                        </span>
                                                        <br />
                                                        {/* <span className="text-xs text-black font-sans">
                                  {flightdata.outBound.length === 1
                                    ? "Non-Stop"
                                    : flightdata.outBound.length === 2
                                    ? "one-Stop"
                                    : flightdata.outBound.length === 3
                                    ? "two-stop"
                                    : null}
                                </span> */}
                                                      </div>
                                                      <div className="col-span-3 pr-2">
                                                        <hr className="bg-black opacity-100" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="text-center py-3">
                                                    <span className="text-lg text-black font-bold">
                                                      {
                                                        item.reachDate.split(
                                                          "T"
                                                        )[0]
                                                      }
                                                    </span>
                                                    <br />
                                                    <span className="text-lg text-black font-bold">
                                                      {convertFrom24To12Format(
                                                        item.reachDate
                                                          .split("T")[1]
                                                          .substring(0, 5)
                                                      )}{" "}
                                                    </span>
                                                    <br />
                                                    <span className="text-lg text-black font-bold">
                                                      {item.toAirport}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="px-4 py-1 md:py-0 user-info-table-heading">
                                        <h4 className="text-left">Return</h4>
                                      </div>
                                      {status.data.supplierFlight.returnFlight.inBound.map(
                                        (item, index) => {
                                          return (
                                            <div className="grid grid-cols-6  mt-2  ">
                                              <div className="col-span-2">
                                                <div className="grid grid-cols-3">
                                                  <div className="pt-2 pb-3">
                                                    <img
                                                      src={`${websiteUrl}/resources/images/airline-logo/${item.airline}.png`}
                                                      className="w-6/12 down rounded-xl inline float-right"
                                                    />
                                                  </div>

                                                  <div className="pl-4 pt-3 col-span-2 ">
                                                    <span className="text-xl text-black font-sans font-bold">
                                                      {item.airlineName}
                                                    </span>
                                                    -
                                                    <span className="text-xs  text-black font-sans font-bold">
                                                      {" "}
                                                      {item.flightNo}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-span-4 px-14">
                                                <div className="grid grid-cols-4">
                                                  <div className="text-center py-3">
                                                    <span className="  text-black font-bold">
                                                      {item.fromAirport},{" "}
                                                      {AirportData.filter(
                                                        (items) =>
                                                          items.airportCode ==
                                                          item.fromAirport
                                                      ).map(
                                                        (items, i) =>
                                                          items.airportName
                                                      )}
                                                    </span>
                                                    <br />
                                                    <span className="  text-black font-bold">
                                                      {/* {item.depDate.split("T")[0]}{" "} */}
                                                      {moment(
                                                        item.depDate
                                                      ).format(
                                                        "dddd, DD-MMMM-yyyy"
                                                      )}{" "}
                                                      <br />
                                                      {moment(
                                                        item.depDate
                                                      ).format("LT")}
                                                    </span>
                                                    {/* <span className="text-lg text-black font-bold">
                                                      {item.fromAirport}
                                                    </span> */}
                                                  </div>
                                                  <div className="col-span-2">
                                                    <div className="grid grid-cols-9 mt-3 ">
                                                      <div className="col-span-3 pl-2">
                                                        <hr className="bg-black opacity-100" />
                                                      </div>
                                                      <div className="col-span-3 text-center relative -top-2">
                                                        <span className="text-xs text-black font-sans">
                                                          <ConvertMinsToTime
                                                            data={item.eft}
                                                          />
                                                        </span>
                                                        <br />
                                                        {/* <span className="text-xs text-black font-sans">
                                  {flightdata.outBound.length === 1
                                    ? "Non-Stop"
                                    : flightdata.outBound.length === 2
                                    ? "one-Stop"
                                    : flightdata.outBound.length === 3
                                    ? "two-stop"
                                    : null}
                                </span> */}
                                                      </div>
                                                      <div className="col-span-3 pr-2">
                                                        <hr className="bg-black opacity-100" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="text-center py-3">
                                                    <span className="  text-black font-bold">
                                                      {item.toAirport},{" "}
                                                      {AirportData.filter(
                                                        (items) =>
                                                          items.airportCode ==
                                                          item.toAirport
                                                      ).map(
                                                        (items, i) =>
                                                          items.airportName
                                                      )}
                                                    </span>
                                                    <br />
                                                    <span className="  text-black font-bold">
                                                      {/* {item.depDate.split("T")[0]}{" "} */}
                                                      {moment(
                                                        item.reachDate
                                                      ).format(
                                                        "dddd, DD-MMMM-yyyy"
                                                      )}{" "}
                                                      <br />
                                                      {moment(
                                                        item.reachDate
                                                      ).format("LT")}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </>
                              ) : null}
                            </div>

                            {/*--------------------------------------------- flight details-------------------------------------- */}

                            {/* -------------------------------------------user detail ---------------------------------*/}
                            <div className="container-fluid px-0">
                              <div className="row">
                                <div className="col-12 text-center">
                                  {/* USER DETAIL */}
                                  <div className="container user-info-popup ups pt-2">
                                    <div className="  w-full rounded-t-2xl text-left mb-2  font-bold text-xs mdtext-xl text-black text-white ">
                                      <i class="fas fa-user text-black  "></i>{" "}
                                      &nbsp; Passenger Details{" "}
                                    </div>
                                    <div id="scrollMax">
                                      <table>
                                        <tr className="user-info-table-heading text-xs py-1 ">
                                          <th className="detail-user1 p-1">
                                            Name
                                          </th>
                                          <th className="detail-user1 p-1">
                                            Email
                                          </th>
                                          <th className="detail-user1 p-1">
                                            Phone Number
                                          </th>
                                          <th className="detail-user1 p-1">
                                            DOB
                                          </th>
                                          <th className="detail-user1 p-1">
                                            Gender
                                          </th>
                                        </tr>
                                        {status.data.passengerDetails.map(
                                          (items, i) => (
                                            <tr className="text-black  ">
                                              <td className="font-bold detail-user p-1">
                                                {items.firstName}{" "}
                                                {items.middleName}{" "}
                                                {items.lastName}
                                              </td>
                                              <td className="font-bold detail-user p-1">
                                                {items.email}
                                              </td>
                                              <td className="font-bold detail-user p-1">
                                                {items.phone}
                                              </td>
                                              <td className="font-bold detail-user p-1">
                                                {items.dob}
                                              </td>
                                              <td className="font-bold detail-user p-1">
                                                {items.gender == 1
                                                  ? "MALE"
                                                  : "FEMALE"}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 text-center">
                                  {/* Price DETAIL */}

                                  <div className="container user-info-popup ups pt-2">
                                    {status.data.paymentDetails != null ? (
                                      <div>
                                        <div className="  w-full rounded-t-2xl text-left mb-2  font-bold text-xl text-black text-white ">
                                          <i class="fas fa-rupee-sign  text-black  "></i>{" "}
                                          &nbsp; Price Details{" "}
                                        </div>
                                        <div className="row">
                                          <table>
                                            <tr className="user-info-table-heading">
                                              <th className="detail-user1 p-1">
                                                Adult Fare
                                              </th>
                                              <th className="detail-user1 p-1">
                                                Adult Tax
                                              </th>
                                              <th className="detail-user1 p-1">
                                                Convenience Fees
                                              </th>
                                              <th className="detail-user1 p-1">
                                                Total Price
                                              </th>
                                            </tr>
                                            <tr>
                                              <td>
                                                <i class="fas fa-rupee-sign  text-black  "></i>{" "}
                                                {status.data.supplierFlight
                                                  .returnFlight == null
                                                  ? status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .baseFare
                                                  : status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .baseFare +
                                                    status.data.supplierFlight
                                                      .returnFlight.fare
                                                      .baseFare}
                                              </td>
                                              <td>
                                                <i class="fas fa-rupee-sign  text-black  "></i>{" "}
                                                {(status.data.supplierFlight
                                                  .returnFlight == null
                                                  ? status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .totalTax +
                                                    status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .AdditionalTxnFeeOfrd +
                                                    status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .totalMarkup
                                                  : status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .totalTax +
                                                    status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .totalMarkup +
                                                    status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .AdditionalTxnFeeOfrd +
                                                    status.data.supplierFlight
                                                      .returnFlight.fare
                                                      .totalTax +
                                                    status.data.supplierFlight
                                                      .returnFlight.fare
                                                      .totalMarkup +
                                                    status.data.supplierFlight
                                                      .returnFlight.fare
                                                      .AdditionalTxnFeeOfrd
                                                )?.toFixed(2)}
                                              </td>
                                              {/* <td>
                                    <i class="fas fa-rupee-sign  text-black  "></i>  {status.data.supplierFlight.onWordFlight.fare != null ? status.data.supplierFlight.onWordFlight.fare.totalMarkup : " "}
                                  </td> */}
                                              <td>
                                                <i class="fas fa-rupee-sign  text-black  "></i>{" "}
                                                {status.data.supplierFlight
                                                  .returnFlight == null
                                                  ? status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .convenienceFees *
                                                    status.data.passengerDetails
                                                      .length
                                                  : status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .convenienceFees *
                                                      status.data
                                                        .passengerDetails
                                                        .length +
                                                    status.data.supplierFlight
                                                      .returnFlight.fare
                                                      .convenienceFees *
                                                      status.data
                                                        .passengerDetails
                                                        .length}
                                              </td>

                                              <td className="text-2xl font-bold   p-2 text-black ">
                                                <i class="fas fa-rupee-sign  text-black  "></i>{" "}
                                                {(status.data.supplierFlight
                                                  .returnFlight == null
                                                  ? status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .baseFare +
                                                    status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .totalMarkup +
                                                    status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .totalTax +
                                                    status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .AdditionalTxnFeeOfrd +
                                                    status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .convenienceFees *
                                                      status.data
                                                        .passengerDetails.length
                                                  : status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .baseFare +
                                                    status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .totalMarkup +
                                                    status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .totalTax +
                                                    status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .AdditionalTxnFeeOfrd +
                                                    status.data.supplierFlight
                                                      .onWordFlight.fare
                                                      .convenienceFees *
                                                      status.data
                                                        .passengerDetails
                                                        .length +
                                                    status.data.supplierFlight
                                                      .returnFlight.fare
                                                      .baseFare +
                                                    status.data.supplierFlight
                                                      .returnFlight.fare
                                                      .totalMarkup +
                                                    status.data.supplierFlight
                                                      .returnFlight.fare
                                                      .totalTax +
                                                    status.data.supplierFlight
                                                      .returnFlight.fare
                                                      .AdditionalTxnFeeOfrd +
                                                    status.data.supplierFlight
                                                      .returnFlight.fare
                                                      .convenienceFees *
                                                      status.data
                                                        .passengerDetails.length
                                                )?.toFixed(2)}
                                              </td>
                                            </tr>
                                          </table>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="container itenary">
                              <div className="row py-4">
                                <div className="col-md-12">
                                  <h3 className="text-primary text-lg font-bold">
                                    e - Ticket Status
                                  </h3>
                                  {/* {<span>{status.data.paymentStatus}{" "}{}</span>} */}
                                  {status.data.paymentStatus == "PENDING" &&
                                  status.data.bookingStatus != "SUCCESS" ? (
                                    <p className="text-black text-sm">
                                      Your payment is failed from bank, Please
                                      contact your bank. your booking is pending
                                      until payment is not clear from the bank.
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {status.data.paymentStatus == "SUCCESS" ? (
                                    <p className="text-black text-sm">
                                      Your e-Ticket will be emailed shortly,
                                      once your ticket is confirmed.
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {status.data.paymentStatus != "SUCCESS" &&
                                  status.data.bookingStatus == "SUCCESS" ? (
                                    <p className="text-black text-sm">
                                      <b>
                                        Your e-Ticket has been emailed. Please
                                        check you email.
                                        <br />
                                        Thank You for choosing Travomint
                                      </b>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* -------------------------------------------------itenery------------------------------------------------- */}
                            {/* <div className="container itenary">
              <div className="row ">
                <h3 className="text-primary text-lg font-bold">Itinery Details -</h3>
                <p className="text-black text-sm">Your Booking is { status.data.bookingStatus} but the payment has failed from the bank, Kindly contact your bank. Your booking is pending please ensure that the payment will be made shortly after. Your booking reference number is</p>
              </div>
            </div> */}
                            {/* -------------------------------------------------itenery------------------------------------------------- */}
                            <Terms />
                          </div>
                          <ConfirmationFooter />
                        </div>
                      </>
                    </>
                  ) : (
                    "No Data Found, Sorry for the innconvienence, Please try to contact with the Technical Team:  +91-1204814491"
                  )}
                </>
              ) : (
                <div>
                  <div className="">
                    <div className="row  col- d-flex align-items-center  justify-content-center bg-white">
                      <div className="col-6 text-center">
                        <img
                          src={logo}
                          className="err_logo-custom mb-4"
                          width="80%"
                        />
                        <div>
                          <img
                            src={Flight_Not_Found}
                            className="flight_not_found_pic"
                          />
                        </div>
                        <div>
                          <h2>You have disturbed order in URL</h2>
                        </div>
                        <a href={`https://www.google.com/travel/flights`}>
                          <div className="go_back_btn">
                            <Button>Go To Home</Button>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* __________________________________ DETAIL_____________________________________ */}
            </div>
          ) : (
            ""
          )}
        </div>

        {/* _____________________________________ THIRD DETAIL_____________________________________  */}
      </div>
    </>
  );
};

export default Confirmation;
