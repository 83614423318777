import React from "react";
import { useLocation } from "react-router-dom";
import FlightConfirmation from "./flightconfirmation";

const TicketConfirmation = () => {
  const location = useLocation();
  return (
    <div>
      {/* {location.state === null ? (
        <h1>404</h1>
      ) : ( */}
      <FlightConfirmation location={location} />
      {/* )} */}
    </div>
  );
};

export default TicketConfirmation;
