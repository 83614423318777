import React, { useState, useRef, useEffect } from "react";
import Footer from "../Atom/Footer";
import Header from "../Atom/Header";
import TopNav from "../Atom/TopNav";
import First from "../Molecule/FinalPage/First";
import Second from "../Molecule/FinalPage/Second";
import Collapse from "react-bootstrap/Collapse";
import { useLocation } from "react-router-dom";
import SideBanner from "../Molecule/FinalPage/SideBanner";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
// import TawkTo from 'tawkto-react'

const Finalpage = (props) => {
  const [ispricechk, setPriceChk] = useState(false);
  const Selected_Fligt_Data = useLocation();
  const currencySign = "₹";
  const [All_Flight_Data, setAllFlightData] = useState(
    Selected_Fligt_Data.state.AllFlight_Data
  );
  const [Flight_Data, setFlightData] = useState(
    Selected_Fligt_Data.state.flight_data
  );
  const [inBoundDATA, setInBoundDATA] = useState(
    Selected_Fligt_Data.state.inBound_data
  );
  const IsPf = Selected_Fligt_Data.state.isPf;
  const [fares, setFares] = useState(Flight_Data?.fare);

  const outBound = Flight_Data?.outBound;
  const totalpassanger = All_Flight_Data.adults + All_Flight_Data.child;
  const [value, setValue] = useState(false);
  const [baggageProtValue, setBaggageProtValue] = useState(false);
  const [travelProtectionValue, setTravelProtectionValue] = useState(false);
  const [valueprice, setValueprice] = useState(0);
  const [baggageProtValueprice, setBaggageProtValueptice] = useState(0);
  const [travelProtection, setTravelProtection] = useState(0);

  // let totalpricedata = 0
  // if(currency_Name_rd.currency_Name === "INR"){
  // let totalpricedata =
  //   fares.grandTotal +
  //   fares.convenienceFees +
  //   valueprice +
  //   baggageProtValueprice +
  //   travelProtection;
  // }else{
  //   fares.grandTotal +

  //     valueprice +
  //     baggageProtValueprice +
  //     travelProtection;
  // }

  const totalpassangerforadditional =
    All_Flight_Data.adults + All_Flight_Data.child;
  // const additionalTax =
  //   fares.AdditionalTxnFeeOfrd / totalpassangerforadditional;
  const additionalTax =
    All_Flight_Data.tripType === 1
      ? fares?.AdditionalTxnFeeOfrd != null
        ? fares?.AdditionalTxnFeeOfrd / totalpassangerforadditional
        : 0.0
      : All_Flight_Data.domestic === true
      ? (fares.AdditionalTxnFeeOfrd + inBoundDATA.fare.AdditionalTxnFeeOfrd) /
        totalpassangerforadditional
      : fares.AdditionalTxnFeeOfrd;

  var totalpricedatas = "";

  if (All_Flight_Data.tripType === 2) {
    if (All_Flight_Data.domestic === true) {
      totalpricedatas = parseFloat(
        fares.baseFare +
          fares.totalMarkup +
          fares.totalTax +
          inBoundDATA.fare.baseFare +
          inBoundDATA.fare.totalMarkup +
          inBoundDATA.fare.totalTax +
          fares.AdditionalTxnFeeOfrd +
          inBoundDATA.fare.AdditionalTxnFeeOfrd +
          fares.convenienceFees * totalpassanger +
          inBoundDATA.fare.convenienceFees * totalpassanger
      ).toFixed(2, 0);
    } else {
      totalpricedatas = parseFloat(
        fares.baseFare +
          fares.totalMarkup +
          fares.totalTax +
          fares.AdditionalTxnFeeOfrd +
          fares.convenienceFees * totalpassanger
      ).toFixed(2, 0);
    }
  } else {
    totalpricedatas = parseFloat(
      fares.baseFare +
        fares.totalMarkup +
        fares.totalTax +
        fares.AdditionalTxnFeeOfrd +
        fares.convenienceFees * totalpassanger
    ).toFixed(2, 0);
  }

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-11193644243"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'AW-11193644243');`}
        </script>
        <script>
          {`gtag('event', 'conversion', {
      'send_to': 'AW-11193644243/N74UCNmfhrIZENPpxdkp',
      'value': 1.0,
      'currency': 'INR',
      'aw_remarketing_only': true
  });`}
        </script>
      </Helmet>
      <Header />
      <TopNav />
      <div className="container">
        <div class="row">
          {All_Flight_Data.priceChange === true && (
            <div className="text-center font-700 price_Changed alert alert-danger ">
              <strong></strong>Price has been updated, please check before
              booking
            </div>
          )}
          <div className="col-xl-8 col-lg-8 col-md-12 col-12 col-span-6 order2">
            <First
              allflightdata={All_Flight_Data}
              flightdata={Flight_Data}
              inBoundData={inBoundDATA}
            />
            <Second
              setValue={setValue}
              value={value}
              setBaggageProtValue={setBaggageProtValue}
              allflightdata={All_Flight_Data}
              flightdata={Flight_Data}
              totalpricedata={totalpricedatas}
              inBoundData={inBoundDATA}
              setValueprice={setValueprice}
              setBaggageProtValueptice={setBaggageProtValueptice}
              setTravelProtectionValue={setTravelProtectionValue}
              setTravelProtection={setTravelProtection}
              setAllFlightData={setAllFlightData}
              setFlightData={setFlightData}
              setInBoundDATA={setInBoundDATA}
              setPriceChk={setPriceChk}
              ispricechk={ispricechk}
              isPf={IsPf}
            />
          </div>

          {All_Flight_Data.tripType === 2 ? (
            <>
              {All_Flight_Data.domestic === false ? (
                <>
                  {/* int */}
                  <div className="col-xl-4 col-lg-4 col-md-4 col-12 col-span-3 pr-2  ">
                    <div className="up text-gray-800    border-gray-300 p-2 ">
                      <h5 className="font-bold  text-travomint px-0 mb-4 mobile-h3 ">
                        <i class="fas fa-credit-card text-blue-500"></i> &nbsp;
                        PRICE DETAILS
                      </h5>

                      {/* 1st */}
                      {/* adultFare */}
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="col-span-3">
                          <p className="text-black font-bold pr-2 text-sm font-700">
                            {All_Flight_Data.adults} Traveller(s) : Adult{" "}
                          </p>
                          <p className="text-sm bg-gray-100 pb-2 mb-0 pr-2">
                            {" "}
                            Flight Charges per adult{" "}
                          </p>
                          <p className="text-sm bg-gray-100 pb-0 mb-2 mt-0 pr-2 ">
                            {" "}
                            Taxes & Fees per adult{" "}
                          </p>
                        </div>
                        <div className="text-right">
                          <p className="text-black font-bold text-sm font-500  travel-p text-right">
                            {" "}
                            {currencySign}{" "}
                            {(
                              parseFloat(
                                fares.adultFare +
                                  fares.adultMarkup +
                                  fares.adultTax +
                                  additionalTax
                              ) * All_Flight_Data.adults
                            ).toFixed(2, 0)}
                          </p>
                          <p className="text-black font-bold text-sm font-500  travel-p text-right">
                            {" "}
                            {currencySign}{" "}
                            {parseFloat(
                              fares.adultFare + additionalTax
                            ).toFixed(2, 0)}
                          </p>
                          <p className="text-black font-bold text-sm font-500  travel-p text-right ">
                            {" "}
                            {currencySign}{" "}
                            {parseFloat(
                              fares.adultTax + fares.adultMarkup
                            ).toFixed(2, 0)}
                          </p>
                        </div>
                      </div>

                      {/* childfare */}
                      {All_Flight_Data.child !== 0 && (
                        <div className="d-flex align-items-center justify-content-between border-top ">
                          <div className="col-span-3">
                            <p className="text-black font-700 pr-2 text-sm">
                              {All_Flight_Data.child} Traveller(s) : Child{" "}
                            </p>
                            <p className="text-sm bg-gray-100 pb-2 mb-0 pr-2">
                              {" "}
                              Flight Charges per Child{" "}
                            </p>
                            <p className="text-sm bg-gray-100 pb-0 mb-2 mt-0 pr-2 ">
                              {" "}
                              Taxes & Fees per Child{" "}
                            </p>
                          </div>
                          <div className="text-right">
                            <p className="text-black font-bold text-sm font-500  travel-p text-right">
                              {" "}
                              {currencySign}{" "}
                              {parseFloat(
                                (fares.childFare +
                                  fares.childTax +
                                  fares.childMarkup +
                                  additionalTax) *
                                  All_Flight_Data.child
                              ).toFixed(2, 0)}
                            </p>
                            <p className="text-black font-bold text-sm font-500  travel-p text-right">
                              {" "}
                              {currencySign}{" "}
                              {parseFloat(
                                fares.childFare + additionalTax
                              ).toFixed(2, 0)}
                            </p>
                            <p className="text-black font-bold text-sm font-500  travel-p text-right ">
                              {" "}
                              {currencySign}{" "}
                              {parseFloat(
                                fares.childTax + fares.childMarkup
                              ).toFixed(2, 0)}
                            </p>
                          </div>
                        </div>
                      )}

                      {/* infantsfare */}
                      {All_Flight_Data.infant !== 0 && (
                        <div className="d-flex align-items-center justify-content-between border-top ">
                          <div className="col-span-3">
                            <p className="text-black font-700 pr-2 text-sm">
                              {All_Flight_Data.infant} Traveller(s) : Infant{" "}
                            </p>
                            <p className="text-sm bg-gray-100 pb-2 mb-0 pr-2">
                              {" "}
                              Flight Charges per infant{" "}
                            </p>
                            <p className="text-sm bg-gray-100 pb-0 mb-2 mt-0 pr-2 ">
                              {" "}
                              Taxes & Fees per infant{" "}
                            </p>
                          </div>
                          <div className="text-right">
                            <p className="text-black font-bold text-sm font-500  travel-p text-right">
                              {" "}
                              {currencySign}{" "}
                              {parseFloat(
                                fares.infantFare +
                                  fares.infantTax +
                                  fares.infantMarkup
                              ).toFixed(2, 0)}
                            </p>
                            <p className="text-black font-bold text-sm font-500  travel-p text-right">
                              {" "}
                              {currencySign}{" "}
                              {parseFloat(fares.infantFare).toFixed(2, 0)}
                            </p>
                            <p className="text-black font-bold text-sm font-500  travel-p text-right ">
                              {" "}
                              {currencySign}{" "}
                              {parseFloat(
                                fares.infantTax + fares.infantMarkup
                              ).toFixed(2, 0)}
                            </p>
                          </div>
                        </div>
                      )}

                      {fares.convenienceFees != null &&
                        fares.convenienceFees > 0 && (
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="col-span-3">
                              <p className="text-black font-bold pr-2 text-sm">
                                Convenience Fees
                              </p>
                            </div>
                            <div className="">
                              <p className="font-weight-bold text-dark">
                                {" "}
                                {currencySign}{" "}
                                {parseFloat(
                                  fares.convenienceFees * totalpassanger
                                ).toFixed(2, 0)}
                              </p>
                            </div>
                          </div>
                        )}

                      {fares.totalMarkup < 0 ? (
                        <>
                          <hr className="mt-0"></hr>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="col-span-3">
                              <p className="text-black font-bold pr-2 text-sm">
                                Discount
                              </p>
                            </div>
                            <div className="col-span-3">
                              <p className="font-weight-bold text-success">
                                {" "}
                                - {currencySign}{" "}
                                {parseFloat(-fares.totalMarkup).toFixed(2, 0)}
                              </p>
                            </div>
                          </div>

                          <div className="row-tprice">
                            <div className="d-flex justify-content-between align-items-center total-price pb-1">
                              <div className="col-span-3 mb-0">
                                <p className="text-black mb-0 font-700 p-mobile">
                                  Total Price :{" "}
                                </p>
                              </div>
                              <div className="col-span-2 text-right mb-0">
                                <p className="mb-0 font-bold p-mobile price-linethrough">
                                  {currencySign}{" "}
                                  {parseFloat(
                                    totalpricedatas - fares.totalMarkup
                                  ).toFixed(2, 0)}
                                </p>
                              </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center total-price pt-0">
                              <div className="col-span-3 mb-0"></div>
                              <div className="col-span-2 text-right mb-0">
                                <p className="text-danger mb-0 font-weight-bold text-xl p-mobile">
                                  {" "}
                                  {currencySign} {totalpricedatas}
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="d-flex justify-content-between align-items-center total-price">
                            <div className="col-span-3 mb-0">
                              <p className="text-black mb-0 font-700 text-xl p-mobile">
                                Total Price :{" "}
                              </p>
                            </div>
                            <div className="col-span-2 text-right mb-0">
                              <p className="text-danger mb-0 font-bold text-xl p-mobile">
                                {" "}
                                {currencySign}{" "}
                                {parseFloat(totalpricedatas).toFixed(2, 0)}
                              </p>
                            </div>
                          </div>
                        </>
                      )}

                      {/* <hr className="mt-0 mb-0" /> */}
                      {/* 2nd */}
                      {/* <div className="d-flex justify-content-between align-items-center total-price">
                        <div className="col-span-3 mb-0">
                          <p className="text-black  mb-0 font-700 text-xl p-mobile">
                            Total Price :{" "}
                          </p>
                        </div>
                        <div className="col-span-2 text-right mb-0">
                          <p className="text-black mb-0 font-bold text-xl p-mobile">
                            {" "}
                            {currencySign} {" "} {totalpricedatas}
                          </p>
                        </div>
                      </div> */}
                      {/* <hr className="mt-0 mb-0" /> */}
                      <div className="grid grid-cols-1   pt-4">
                        <span className="text-xs text-gray-700">
                          <strong>NOTE: </strong> All Fares displayed are quoted
                          in INR and inclusive of base fare, taxes and all fees
                          Additional baggage fees may apply as per the airline
                          policies..
                        </span>
                      </div>
                    </div>
                    <SideBanner />
                  </div>
                </>
              ) : (
                <div className="col-xl-4 col-lg-4 col-md-12 col-12 col-span-3 pricing-tag-mobile">
                  <div className="up text-gray-800    border-gray-300 p-4">
                    <h5 className="font-bold  text-travomint px-0 mb-4 mobile-h3">
                      <i class="fas fa-credit-card text-blue-500"></i> &nbsp;
                      PRICE DETAILS
                    </h5>

                    {/* 1st */}
                    {/* adultFare */}
                    <div className="d-flex align-items-center justify-content-between ">
                      <div className="col-span-3">
                        <p className=" font-bold pr-2 text-sm text-black font-700 travellar-adult-css">
                          {All_Flight_Data.adults} Traveller(s) : Adult{" "}
                        </p>
                        <p className="text-sm bg-gray-100 pb-2 mb-0 pr-2 text-black ">
                          {" "}
                          Flight Charges per adult{" "}
                        </p>
                        <p className="text-sm bg-gray-100 pb-0 mb-2 mt-0 pr-2 text-black ">
                          {" "}
                          Taxes & Fees per adult{" "}
                        </p>
                      </div>
                      <div className="col-span-3">
                        <p className="text-black font-bold text-sm font-700 travel-p text-right">
                          {" "}
                          {currencySign}{" "}
                          {parseFloat(
                            (fares.adultFare +
                              fares.adultMarkup +
                              fares.adultTax +
                              inBoundDATA.fare.adultFare +
                              inBoundDATA.fare.adultMarkup +
                              inBoundDATA.fare.adultTax +
                              additionalTax) *
                              All_Flight_Data.adults
                          ).toFixed(2, 0)}
                        </p>
                        <p className="text-black font-bold text-sm font-500  travel-p text-right">
                          {" "}
                          {currencySign}{" "}
                          {parseFloat(
                            fares.adultFare +
                              inBoundDATA.fare.adultFare +
                              additionalTax
                          ).toFixed(2, 0)}
                        </p>
                        <p className="text-black font-bold text-sm font-500  travel-p text-right">
                          {" "}
                          {currencySign}{" "}
                          {parseFloat(
                            fares.adultTax +
                              fares.adultMarkup +
                              inBoundDATA.fare.adultTax +
                              inBoundDATA.fare.adultMarkup
                          ).toFixed(2, 0)}
                        </p>
                      </div>
                    </div>

                    {/* childfare */}
                    {All_Flight_Data.child !== 0 && (
                      <div className="d-flex align-items-center justify-content-between border-top">
                        <div className="col-span-3">
                          <p className="text-black font-700 pr-2 text-sm">
                            {All_Flight_Data.child} Traveller(s) : Child{" "}
                          </p>
                          <p className="text-sm bg-gray-100 pb-2 mb-0 pr-2">
                            {" "}
                            Flight Charges per Child{" "}
                          </p>
                          <p className="text-sm bg-gray-100 pb-0 mb-2 mt-0 pr-2 ">
                            {" "}
                            Taxes & Fees per Child{" "}
                          </p>
                        </div>
                        <div className="text-right">
                          <p className="text-black font-bold text-sm font-500  travel-p text-right">
                            {" "}
                            {currencySign}{" "}
                            {parseFloat(
                              (fares.childFare +
                                fares.childMarkup +
                                fares.childTax +
                                inBoundDATA.fare.childFare +
                                inBoundDATA.fare.childMarkup +
                                inBoundDATA.fare.childTax +
                                additionalTax) *
                                All_Flight_Data.child
                            ).toFixed(2, 0)}
                          </p>
                          <p className="text-black font-bold text-sm font-500  travel-p text-right">
                            {" "}
                            {currencySign}{" "}
                            {parseFloat(
                              fares.childFare +
                                inBoundDATA.fare.childFare +
                                additionalTax
                            ).toFixed(2, 0)}
                          </p>
                          <p className="text-black font-bold text-sm font-500  travel-p text-right">
                            {" "}
                            {currencySign}{" "}
                            {parseFloat(
                              fares.childTax +
                                fares.childMarkup +
                                inBoundDATA.fare.childTax +
                                inBoundDATA.fare.childMarkup
                            ).toFixed(2, 0)}
                          </p>
                        </div>
                      </div>
                    )}

                    {/* infantsfare */}
                    {All_Flight_Data.infant !== 0 && (
                      <div className="d-flex align-items-center justify-content-between border-top ">
                        <div className="col-span-3">
                          <p className="text-black font-700 pr-2 text-sm">
                            {All_Flight_Data.infant} Traveller(s) : Infant{" "}
                          </p>
                          <p className="text-sm bg-gray-100 pb-2 mb-0 pr-2">
                            {" "}
                            Flight Charges per infant{" "}
                          </p>
                          <p className="text-sm bg-gray-100 pb-0 mb-2 mt-0 pr-2 ">
                            {" "}
                            Taxes & Fees per infant{" "}
                          </p>
                        </div>
                        <div className="text-right">
                          <p className="text-black font-bold text-sm font-500  travel-p text-right">
                            {" "}
                            {currencySign}{" "}
                            {parseFloat(
                              (fares.infantFare +
                                fares.infantMarkup +
                                fares.infantTax +
                                inBoundDATA.fare.infantFare +
                                inBoundDATA.fare.infantMarkup +
                                inBoundDATA.fare.infantTax) *
                                All_Flight_Data.infant
                            ).toFixed(2, 0)}
                          </p>
                          <p className="text-black font-bold text-sm font-500  travel-p text-right">
                            {" "}
                            {currencySign}{" "}
                            {parseFloat(
                              fares.infantFare + inBoundDATA.fare.infantFare
                            ).toFixed(2, 0)}
                          </p>
                          <p className="text-black font-bold text-sm font-500  travel-p text-right">
                            {" "}
                            {currencySign}{" "}
                            {parseFloat(
                              fares.infantTax +
                                fares.infantMarkup +
                                inBoundDATA.fare.infantTax +
                                inBoundDATA.fare.infantMarkup
                            ).toFixed(2, 0)}
                          </p>
                        </div>
                      </div>
                    )}
                    {fares.convenienceFees != null &&
                      fares.convenienceFees > 0 && (
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="col-span-3">
                            <p className="text-black font-bold pr-2 text-sm">
                              Convenience Fees
                            </p>
                          </div>
                          <div className="col-span-3">
                            <p className="font-weight-bold text-dark">
                              {" "}
                              {currencySign}{" "}
                              {parseFloat(
                                (fares.convenienceFees +
                                  inBoundDATA.fare.convenienceFees) *
                                  totalpassanger
                              ).toFixed(2, 0)}
                            </p>
                          </div>
                        </div>
                      )}

                    {fares.totalMarkup < 0 ? (
                      <>
                        <hr className="mt-0"></hr>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="col-span-3">
                            <p className="text-black font-bold pr-2 text-sm">
                              Discount
                            </p>
                          </div>
                          <div className="col-span-3">
                            <p className="font-weight-bold text-success">
                              {" "}
                              - {currencySign}{" "}
                              {parseFloat(
                                -(
                                  fares.totalMarkup +
                                  inBoundDATA.fare.totalMarkup
                                )
                              ).toFixed(2, 0)}
                            </p>
                          </div>
                        </div>

                        <div className="row-tprice">
                          <div className="d-flex justify-content-between align-items-center total-price pb-1">
                            <div className="col-span-3 mb-0">
                              <p className="text-black mb-0 font-700 p-mobile">
                                Total Price :{" "}
                              </p>
                            </div>
                            <div className="col-span-2 text-right mb-0">
                              <p className="mb-0 font-bold p-mobile price-linethrough">
                                {currencySign}{" "}
                                {parseFloat(
                                  totalpricedatas -
                                    (fares.totalMarkup +
                                      inBoundDATA.fare.totalMarkup)
                                ).toFixed(2, 0)}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-center total-price pt-0">
                            <div className="col-span-3 mb-0"></div>
                            <div className="col-span-2 text-right mb-0">
                              <p className="text-danger mb-0 font-weight-bold text-xl p-mobile">
                                {" "}
                                {currencySign} {totalpricedatas}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="d-flex justify-content-between align-items-center total-price">
                          <div className="col-span-3 mb-0">
                            <p className="text-black mb-0 font-700 text-xl p-mobile">
                              Total Price :{" "}
                            </p>
                          </div>
                          <div className="col-span-2 text-right mb-0">
                            <p className="text-danger mb-0 font-bold text-xl p-mobile">
                              {" "}
                              {currencySign}{" "}
                              {parseFloat(totalpricedatas).toFixed(2, 0)}
                            </p>
                          </div>
                        </div>
                      </>
                    )}

                    {/* <hr className="mt-0 mb-0" /> */}
                    {/* 2nd */}
                    {/* <div className="d-flex justify-content-between align-items-center total-price">
                      <div className="col-span-3 mb-0">
                        <p className="text-black mb-0 font-700 text-xl p-mobile">
                          Total Price :{" "}
                        </p>
                      </div>
                      <div className="col-span-2 text-right mb-0">
                        <p className="text-black mb-0 font-bold text-xl p-mobile">
                          {" "}
                          {currencySign} {" "} {totalpricedatas}
                        </p>
                      </div>
                    </div> */}
                    {/* <hr className="mt-0 mb-0" /> */}
                    <div className="grid grid-cols-1   pt-4">
                      <span className="text-xs text-gray-700">
                        <strong>NOTE: </strong> All Fares displayed are quoted
                        in INR and inclusive of base fare, taxes and all fees
                        Additional baggage fees may apply as per the airline
                        policies..
                      </span>
                    </div>
                  </div>
                  <SideBanner />
                </div>
              )}
            </>
          ) : (
            <div className="col-xl-4 col-lg-4 col-md-4 col-12 col-span-3 px-6">
              <div className="up text-gray-800 border-gray-300 p-2">
                <h5 className="font-bold  text-travomint px-0 mb-4 mobile-h3">
                  <i class="fas fa-credit-card text-blue-500"></i> &nbsp; PRICE
                  DETAILS
                </h5>

                {/* 1st */}
                {/* adultFare */}
                <div className="d-flex align-items-center justify-content-between">
                  <div className="col-span-3">
                    <p className="text-black font-bold pr-2 text-sm font-700">
                      {All_Flight_Data.adults} Traveller(s) : Adult{" "}
                    </p>
                    <p className="text-sm bg-gray-100 pb-2 mb-0 pr-2">
                      {" "}
                      Flight Charges per adult{" "}
                    </p>
                    <p className="text-sm bg-gray-100 pb-0 mb-2 mt-0 pr-2 ">
                      {" "}
                      Taxes & Fees per adult{" "}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="text-black font-bold text-sm font-500  travel-p text-right">
                      {" "}
                      {currencySign}{" "}
                      {parseFloat(
                        (fares.adultFare +
                          fares.adultMarkup +
                          fares.adultTax +
                          additionalTax) *
                          All_Flight_Data.adults
                      ).toFixed(2, 0)}
                    </p>
                    <p className="text-black font-bold text-sm font-500  travel-p text-right">
                      {" "}
                      {currencySign}{" "}
                      {parseFloat(fares.adultFare + additionalTax).toFixed(
                        2,
                        0
                      )}
                    </p>
                    <p className="text-black font-bold text-sm font-500  travel-p text-right ">
                      {" "}
                      {currencySign}{" "}
                      {parseFloat(fares.adultTax + fares.adultMarkup).toFixed(
                        2,
                        0
                      )}
                    </p>
                  </div>
                </div>

                {/* childfare */}
                {All_Flight_Data.child !== 0 && (
                  <div className="d-flex align-items-center justify-content-between ">
                    <div className="col-span-3">
                      <p className="text-black font-700 pr-2 text-sm">
                        {All_Flight_Data.child} Traveller(s) : Child{" "}
                      </p>
                      <p className="text-sm bg-gray-100 pb-2 mb-0 pr-2">
                        {" "}
                        Flight Charges per Child{" "}
                      </p>
                      <p className="text-sm bg-gray-100 pb-0 mb-2 mt-0 pr-2 ">
                        {" "}
                        Taxes & Fees per Child{" "}
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="text-black font-bold text-sm font-500  travel-p text-right">
                        {" "}
                        {currencySign}{" "}
                        {parseFloat(
                          (fares.childFare +
                            fares.childMarkup +
                            fares.childTax +
                            additionalTax) *
                            All_Flight_Data.child
                        ).toFixed(2, 0)}
                      </p>
                      <p className="text-black font-bold text-sm font-500  travel-p text-right">
                        {" "}
                        {currencySign}{" "}
                        {parseFloat(fares.childFare + additionalTax).toFixed(
                          2,
                          0
                        )}
                      </p>
                      <p className="text-black font-bold text-sm font-500  travel-p text-right ">
                        {" "}
                        {currencySign}{" "}
                        {parseFloat(fares.childTax + fares.childMarkup).toFixed(
                          2,
                          0
                        )}
                      </p>
                    </div>
                  </div>
                )}

                {/* infantsfare */}
                {All_Flight_Data.infant !== 0 && (
                  <div className="d-flex align-items-center justify-content-between border-top ">
                    <div className="col-span-3">
                      <p className="text-black font-700 pr-2 text-sm">
                        {All_Flight_Data.infant} Traveller(s) : Infant{" "}
                      </p>
                      <p className="text-sm bg-gray-100 pb-2 mb-0 pr-2">
                        {" "}
                        Flight Charges per infant{" "}
                      </p>
                      <p className="text-sm bg-gray-100 pb-0 mb-2 mt-0 pr-2 ">
                        {" "}
                        Taxes & Fees per infant{" "}
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="text-black font-bold text-sm font-500  travel-p text-right">
                        {" "}
                        {currencySign}{" "}
                        {(
                          (fares.infantFare +
                            fares.infantTax +
                            fares.infantMarkup) *
                          All_Flight_Data.infant
                        ).toFixed(2, 0)}
                      </p>
                      <p className="text-black font-bold text-sm font-500  travel-p text-right">
                        {" "}
                        {currencySign}{" "}
                        {parseFloat(fares.infantFare).toFixed(2, 0)}
                      </p>
                      <p className="text-black font-bold text-sm font-500  travel-p text-right ">
                        {" "}
                        {currencySign}{" "}
                        {parseFloat(
                          fares.infantTax + fares.infantMarkup
                        ).toFixed(2, 0)}
                      </p>
                    </div>
                  </div>
                )}

                {fares.convenienceFees != null && fares.convenienceFees > 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col-span-3">
                      <p className="text-black font-bold pr-2 text-sm">
                        Convenience Fees
                      </p>
                    </div>
                    <div className="">
                      <p className="font-weight-bold text-dark">
                        {" "}
                        {currencySign}
                        {parseFloat(
                          fares.convenienceFees * totalpassanger
                        ).toFixed(2, 0)}
                      </p>
                    </div>
                  </div>
                )}

                {fares.totalMarkup < 0 ? (
                  <>
                    <hr className="mt-0"></hr>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="col-span-3">
                        <p className="text-black font-bold pr-2 text-sm">
                          Discount
                        </p>
                      </div>
                      <div className="col-span-3">
                        <p className="font-weight-bold text-success">
                          {" "}
                          - {currencySign}{" "}
                          {parseFloat(-fares.totalMarkup).toFixed(2, 0)}
                        </p>
                      </div>
                    </div>

                    <div className="row-tprice">
                      <div className="d-flex justify-content-between align-items-center total-price pb-1">
                        <div className="col-span-3 mb-0">
                          <p className="text-black mb-0 font-700 p-mobile">
                            Total Price :{" "}
                          </p>
                        </div>
                        <div className="col-span-2 text-right mb-0">
                          <p className="mb-0 font-bold p-mobile price-linethrough">
                            {currencySign}{" "}
                            {parseFloat(
                              totalpricedatas - fares.totalMarkup
                            ).toFixed(2, 0)}
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-center total-price pt-0">
                        <div className="col-span-3 mb-0"></div>
                        <div className="col-span-2 text-right mb-0">
                          <p className="text-danger mb-0 font-weight-bold text-xl p-mobile">
                            {" "}
                            {currencySign} {totalpricedatas}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-between align-items-center total-price">
                      <div className="col-span-3 mb-0">
                        <p className="text-black mb-0 font-700 text-xl p-mobile">
                          Total Price :{" "}
                        </p>
                      </div>
                      <div className="col-span-2 text-right mb-0">
                        <p className="text-danger mb-0 font-bold textd-xl p-mobile">
                          {" "}
                          {ispricechk && (
                            <Spinner
                              animation="border"
                              size="sm"
                              style={{
                                color: "gray",
                                fontSize: "10px",
                                marginRight: "4px",
                              }}
                            />
                          )}
                          {currencySign}{" "}
                          {parseFloat(totalpricedatas).toFixed(2, 0)}
                        </p>
                      </div>
                    </div>
                  </>
                )}

                <Collapse in={value}>
                  <div className="grid grid-cols-4  px-6 text-sm font-bold text-red-500">
                    <div className="col-span-3">Medical Charge</div>
                    <div className="px-1">
                      <p>
                        {currencySign} {valueprice}
                      </p>
                    </div>
                  </div>
                </Collapse>
                <Collapse in={baggageProtValue}>
                  <div className="grid grid-cols-4  px-6 text-sm font-bold text-red-500">
                    <div className="col-span-3">Baggage Protection</div>
                    <div className="px-1">
                      <p>
                        {" "}
                        {currencySign} {baggageProtValueprice}
                      </p>
                    </div>
                  </div>
                </Collapse>
                <Collapse in={travelProtectionValue}>
                  <div className="grid grid-cols-4  px-6 text-sm font-bold text-red-500">
                    <div className="col-span-3">Travel Protection</div>
                    <div className="px-1">
                      <p>
                        {" "}
                        {currencySign} {travelProtection}
                      </p>
                    </div>
                  </div>
                </Collapse>

                {/* <hr className="mt-0 mb-0" /> */}
                {/* 2nd */}
                {/* <div className="d-flex justify-content-between align-items-center total-price pr-0 ">
                  <div className="col-span-3 mb-0">
                    <p className="text-black mb-0 font-700 text-xl font-700">
                      Total Price :{" "}
                    </p>
                  </div>
                  <div className="col-span-2 text-right mb-0">
                    <p className="text-black mb-0 font-bold text-xl">
                      {" "}
                      {currencySign}  {" "} {totalpricedatas}
                    </p>
                  </div>
                </div> */}
                {/* <hr className="mt-0 mb-0" /> */}
                <div className="grid grid-cols-1">
                  <span className="text-xs text-gray-700">
                    <strong>NOTE: </strong> All Fares displayed are quoted in
                    INR and inclusive of base fare, taxes and all fees
                    Additional baggage fees may apply as per the airline
                    policies..
                  </span>
                </div>
              </div>
              <SideBanner />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Finalpage;
