export const domain = "testapi.traveloes.com";
export const imgdomain = "www.travomint.com";
export const authCode = "Trav3103s987876";
export const siteID = 6;
export const testApiUrl = "https://testapi.traveloes.com";
export const PayU_AuthCode = "YOURFATHER007";

//=------------------------Production-------------------------------
export const PayUAPI = "https://payment.travomint.com/payment";
export const googlrBaseUrl = "https://vbs.travomint.com/GOOGLE";
export const paytmApi = "http://paytm.travomint.com";
export const websiteUrl = "https://www.travomint.com";
export const mailBaseUrl = "https://ind.travomint.com";

// ----------------------------Test--------------------
// export const PayUAPI = "http://10.20.1.86:8082";
// export const googlrBaseUrl = "http://10.20.1.71:8008";
// export const websiteUrl = "http://10.20.1.63:3000";
// export const paytmApi = "http://10.20.1.31:8383";
// export const mailBaseUrl = "http://10.20.1.48:8000";
