import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";

const FlightConfirmation = (props) => {
  window.onpopstate = function (event) {
    if (event) {
      window.location.href = "https://www.google.com/travel/flights";
      // Code to handle back button or prevent from navigation
    }
  };
  const orderID = useParams();
  const navigate = useNavigate();
  sessionStorage.setItem("is_Ml", true);
  useEffect(() => {
    navigate("/confirmation", { state: orderID });
  }, []);

  return <></>;
};

export default FlightConfirmation;
