import React, { createContext, useEffect, useState, useRef } from "react";
import "../../css/second.css";
import { useNavigate } from "react-router-dom";
import { Collapse, Button, Modal } from "react-bootstrap";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import {
  googlrBaseUrl,
  PayUAPI,
  PayU_AuthCode,
  paytmApi,
} from "../../static/static";
import moment from "moment";
import { SKIP_VALUES } from "../../Redux/ActionType";
import { useDispatch, useSelector } from "react-redux";
import loaderimage from "../../Image/load.gif";
import AirPortData from "../../Sample_Data/AirPortData.json";
import countryPhoneCodes from "../../Sample_Data/countryPhoneCodes.json";
import axios from "axios";

const Second = (props) => {
  const navigate = useNavigate();
  window.onpopstate = function (event) {
    if (event) {
      window.location.href = "https://www.google.com/travel/flights";
      // Code to handle back button or prevent from navigation
    }
  };
  const dispatch = useDispatch();
  const { value_data } = useSelector((state) => state.SkipValue_Reducer);
  const {
    allflightdata,
    flightdata,
    totalpricedata,
    setBaggageProtValue,
    setValueprice,
    setBaggageProtValueptice,
    setValue,
    setTravelProtectionValue,
    setTravelProtection,
    isPf,
    value,
    setAllFlightData,
    setFlightData,
    setInBoundDATA,
    setPriceChk,
    ispricechk,
  } = props;
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState(true);
  const [gstinfo, setGstinfo] = useState(false);
  const [bookingnewemail, setcontinueemail] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [address, setAddress] = useState(false);
  const [third, setthird] = useState(false);
  const [continuebutton, setcontinuebutton] = useState(false);

  const handleChange = () => {
    setGstinfo(!gstinfo);
    {
      gstinfo
        ? inputList.map((items, i) => {
            if (
              items.email !== "" &&
              items.phone !== "" &&
              inputList[0].countryCodeNo !== ""
            ) {
              setContinueSave(false);
            } else {
              setContinueSave(true);
            }
          })
        : setContinueSave(true);
    }
  };

  // const traveller =
  // allflightdata.adults + allflightdata.child + allflightdata.infants;
  const Adult_traveller = allflightdata.adults;
  const Child_traveller = allflightdata.child;
  const Infant_traveller = allflightdata.infant;

  // const handleChange = (checked) => {
  //   if (checked.target.checked == true) {
  //     setGstinfo(false)
  //   }else{
  //     setGstinfo(true)
  // }

  // const [checked, setChecked] = useState(false);

  const [gstnum, setGstnum] = useState("");
  const [gstCompany, setCompanygst] = useState();

  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [Mob, setValueMobile] = useState(mobile);
  const [Eml, setValueEmail] = useState(email);

  const [contact, setcontact] = useState(false);
  const [secondcontinuebutton, setsecondcontinuebutton] = useState(false);
  const [baggage, setbaggage] = useState(false);
  const [pay, setpay] = useState(false);
  const [paymode, setPayMode] = useState({
    payU: true,
    paytm: false,
  });
  const [validated, setValidated] = useState(false);
  const [validatedMobile, setValidatedMobile] = useState("");

  const [noOfTravellers, setNoOfTravellers] = useState();

  const [childTravellers, setChildTravellers] = useState();
  const [infantTravellers, setInfantTravellers] = useState();
  const [userphoneNoCode, setUserphoneNoCode] = useState("");

  const [loader, setLoader] = useState(false);

  let textInput = React.createRef();

  const adultminsDate = moment()
    .subtract(120, "year")
    .format("YYYY-MM-DD")
    .toString();
  const adultmaxsDate = moment()
    .subtract(12, "year")
    .format("YYYY-MM-DD")
    .toString();
  const childminsDate = moment()
    .subtract(12, "year")
    .format("YYYY-MM-DD")
    .toString();
  const childmaxsDate = moment()
    .subtract(2, "year")
    .format("YYYY-MM-DD")
    .toString();
  const InfantmaxsDate = moment().format("YYYY-MM-DD").toString();
  const InfantminsDate = moment()
    .subtract(2, "year")
    .format("YYYY-MM-DD")
    .toString();

  const Merged = [];
  const [inputList, setInputList] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      gender: "",
      email: email,
      phone: "",
      passportNumber: "",
      passportExpiry: "",
      passportIssuedCountry: "",
      passportIssued: "",
      passengerType: "1",
      gstNumber: "",
      gstCompany: "",
      country: "India",
      countryCode: "IN",
      countryCodeNo: "91",
    },
  ]);

  const [addressList, setAddList] = useState([
    {
      address1: "",
      address2: "",
      state: "",
      country: "",
      city: "",
      zipcode: "",
    },
  ]);

  const [childinputList, setChildinputList] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      gender: "",
      email: email,
      phone: `${userphoneNoCode} ${mobile}`,
      passportNumber: "",
      passportExpiry: "",
      passportIssuedCountry: "",
      passportIssued: "",
      passengerType: "2",
    },
  ]);

  const [infantinputList, setInfantinputList] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      gender: "",
      email: email,
      phone: `${!userphoneNoCode} ${mobile}`,
      passportNumber: "",
      passportExpiry: "",
      passportIssuedCountry: "",
      passportIssued: "",
      passengerType: "3",
    },
  ]);

  if (
    Adult_traveller !== 0 &&
    Child_traveller !== 0 &&
    Infant_traveller !== 0
  ) {
    Merged.push(...inputList);
    Merged.push(...childinputList);
    Merged.push(...infantinputList);
  } else if (Adult_traveller !== 0 && Child_traveller !== 0) {
    Merged.push(...inputList);
    Merged.push(...childinputList);
  } else if (Adult_traveller !== 0 && Infant_traveller !== 0) {
    Merged.push(...inputList);
    Merged.push(...infantinputList);
  } else if (Adult_traveller !== 0) {
    Merged.push(...inputList);
  }

  // state for validation
  const [mediacalCheckNo, setmediacalCheckNo] = useState();
  const [baggageProt, setBaggageProt] = useState();
  const [travelProt, setTravelPort] = useState();
  const [baggageProterr, setBaggageProterr] = useState("");
  const formRefs = useRef();

  const [show, setShow] = useState(false);
  const [cardData, setcardData] = useState("payuupi");
  const handleClose = () => setShow(false);

  //----------------------------------------------------- MAKE PAYMENT//-----------------------------------------------------

  const [agree, setAgree] = useState(true);
  // function makePaymentpayU(){
  function emailcontinue() {
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    dispatch({ type: SKIP_VALUES, payload: 1 });
    const regexphoneNo =
      /^([\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    if (regEx.test(email) && regexphoneNo.test(mobile)) {
      setValidated("Email is Valid");
      setValidatedMobile("Phone No is Valid");
      setcontinueemail(false);
      setOpen(true);
      setOpen2(true);
      setthird(false);
      setcontinuebutton(false);
      setsecondcontinuebutton(false);
      setInfo(false);
      setcontinuebutton(true);
    } else if (
      !regEx.test(email) &&
      email !== "" &&
      !regexphoneNo.test(mobile) &&
      mobile !== ""
    ) {
      setValidated("Email is Not Valid");
      setValidatedMobile("Phone No is Not Valid");
    } else if (!regEx.test(email) && email !== "") {
      setValidated("Email is Not Valid");
    } else if (!regexphoneNo.test(mobile) && mobile !== "") {
      setValidatedMobile("Phone No is Not Valid");
    }
  }

  // input Contact info
  const fillmobile = (v) => {
    const contact = v.target;
    setmobile(contact.value);
    setValueMobile(mobile);
  };

  const fillemail = (u) => {
    const contact = u.target;
    setemail(contact.value);
    setValueEmail(email);
  };

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    const list = [...inputList];
    const textValue = value.toUpperCase();
    list[index][name] = textValue;
    setInputList(list);
    checkContinue();
    confirmDetailPassenger();
  };

  const handleInputChangedetails = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];

    list[index][name] = value;
    setInputList(list);
    checkContinue();
  };

  const handleInputChangeReg = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];

    list[index][name] = value;
    setInputList(list);
    checkReg();
  };

  function checkContinue() {
    setGstinfo(false);

    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var mobilePhone = /^\d{10}$/;
    var registrationNumber = /^\d{16}$/;
    var companyName = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;

    if (gstinfo == true) {
    } else {
      {
        inputList.map((items, i) => {
          if (
            items.email !== "" &&
            items.phone !== "" &&
            items.email.match(validRegex) &&
            items.phone.match(mobilePhone) &&
            inputList[0].countryCodeNo !== ""
          ) {
            setContinueSave(false);
          } else {
            setContinueSave(true);
          }
        });
      }
    }
  }

  function checkReg() {
    var regnumber = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    var companyName = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;

    // if (gstinfo == true) {
    {
      inputList.map((items, i) => {
        if (
          items.gstNumber !== "" &&
          items.gstNumber.match(regnumber) &&
          items.gstCompany !== "" &&
          items.gstCompany.match(companyName)
        ) {
          setContinueSave(false);
        } else {
          setContinueSave(true);
        }
      });
    }
  }

  function confirmDetailPassenger() {
    var Name = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;

    {
      inputList.map((items, i) => {
        if (
          items.firstName !== "" &&
          items.lastName !== "" &&
          items.firstName.match(Name) &&
          items.lastName.match(Name)
        ) {
          setConfirmdetail(false);
        } else {
          setConfirmdetail(true);
        }
      });
    }
  }

  const handleShow = () => setShow(true);

  const [confirmdetail, setConfirmdetail] = useState(true);

  const handleInputChangenew = (e, index) => {
    const { name, value } = e.target;
    const list = [...addressList];

    list[index][name] = value.toUpperCase();
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleRemoveClicknew = (index) => {
    const list = [...addressList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleSubmitdetails = async (e) => {
    e.preventDefault();
    setInfo(false);
    setpay(false);
    setOpen(true);
    setOpen2(true);
    setAddress(false);
    if (isPf) {
      setPriceChk(true);
      try {
        const { data } = await axios.post(
          `${googlrBaseUrl}/flight/priceVerify`,
          {
            supplierToken: allflightdata?.token,
          }
        );

        const newRes = data?.data?.supplierList[0];
        if (newRes) {
          setAllFlightData(newRes);
          setFlightData(newRes?.onWordFlight);
          setInBoundDATA(newRes?.returnFlight);
        }
      } catch (err) {
        console.log(err);
      }
      setPriceChk(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setInfo(false);
    // setChecked(!true);
    setpay(true);

    setOpen(false);
    setOpen2(true);
    setAddress(false);
    // setLoader(true);
    // setOpen(false);
    // setOpen2(true);
    // setthird(true);
    // setcontinuebutton(false);
    // setsecondcontinuebutton(true);
    // setcontinueemail(false);
    // setcontinueemail(false);
  };

  const handleSubmitnew = (e) => {
    // textInput.current.value;
    e.preventDefault();

    setpay(true);
    setOpen(false);
    setOpen2(true);
    setAddress(false);
    // setLoader(true);
    // setOpen(false);
    // setOpen2(true);
    // setthird(true);
    // setcontinuebutton(false);
    // setsecondcontinuebutton(true);
    // setcontinueemail(false);
    // setcontinueemail(false);

    dispatch({ type: SKIP_VALUES, payload: 2 });
    // e.preventDefault();
    // setLoader(true);
    // setOpen(false);
    setOpen2(true);
  };

  const childhandleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...childinputList];
    const textValue = value.toUpperCase();
    list[index][name] = textValue;
    setChildinputList(list);
  };
  const infanthandleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...infantinputList];
    const textValue = value.toUpperCase();
    list[index][name] = textValue;
    setInfantinputList(list);
  };

  function thirdcontinue() {
    if (
      baggageProt === 0 ||
      (baggageProt === 1 && travelProt === 1) ||
      travelProt === 0
    ) {
      dispatch({ type: SKIP_VALUES, payload: 3 });
    } else {
      setBaggageProterr("Please Select");
    }
  }

  // done the value
  const Done = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];

    list[index][name] = "";
    setInputList(list);
  };

  const traveller =
    allflightdata.adults + allflightdata.child + allflightdata.infant;

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        firstName: "",
        middleName: "",
        dob: "",
        lastName: "",
        gender: "",
        gstnumber: "",
        companyName: "",
        passportNumber: "",
        passportExpiry: "",
        passportIssued: "",
        passportIssuedCountry: "",
        passengerType: "1",
        email: email,
        phone: mobile,
      },
    ]);
    setNoOfTravellers(noOfTravellers - 1);
  };

  const childhandleAddClick = () => {
    setChildinputList([
      ...childinputList,
      {
        firstName: "",
        middleName: "",
        dob: "",
        lastName: "",
        gender: "",
        gstnumber: "",
        companyName: "",
        passportNumber: "",
        passportExpiry: "",
        passportIssued: "",
        passportIssuedCountry: "",
        passengerType: "2",
      },
    ]);
    setChildTravellers(Child_traveller - 1);
  };

  const infanthandleAddClick = () => {
    setInfantinputList([
      ...infantinputList,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        gender: "",
        gstnumber: "",
        companyName: "",
        passportNumber: "",
        passportExpiry: "",
        passportIssued: "",
        passportIssuedCountry: "",
        passengerType: "3",
      },
    ]);
    setInfantTravellers(Infant_traveller - 1);
  };

  const confmedical = () => {
    setValue(true);
    setValueprice(20);
    setmediacalCheckNo(1);
  };
  const noconfmedical = () => {
    setValue(false);
    setValueprice(0);
    setmediacalCheckNo(0);
  };
  const baggageProtactionYes = () => {
    setBaggageProtValue(true);
    setBaggageProtValueptice(380);
    setBaggageProt(1);
  };
  const baggageProtactionNo = () => {
    setBaggageProtValue(false);
    setBaggageProtValueptice(0);
    setBaggageProt(0);
  };
  const travelProtection = () => {
    setTravelProtectionValue(true);
    setTravelProtection(2700);
    setTravelPort(1);
  };
  const travelProtectionNo = () => {
    setTravelProtectionValue(false);
    setTravelProtection(0);
    setTravelPort(0);
  };

  const [crmData, setcrmData] = useState({});
  const [loadCrm, setloadCrm] = useState(false);
  const [continueSave, setContinueSave] = useState(true);

  // function saveCustomerDetail() {
  //   const Modifieddata = {
  //     ...Merged[0],
  //     phone: `+${inputList[0].countryCodeNo} ${inputList[0].phone}`,
  //   };

  //   Merged.shift();

  //   Merged.unshift(Modifieddata);

  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     source: allflightdata.source,
  //     gdsType: allflightdata.gdsType,
  //     onWordFlight: allflightdata.onWordFlight,
  //     returnFlight: allflightdata.returnFlight,
  //     bookingResponse: allflightdata.bookingResponse,
  //     paymentDetails: allflightdata.paymentDetails,
  //     domestic: allflightdata.domestic,
  //     error: allflightdata.error,
  //     tripType: allflightdata.tripType,
  //     priceChange: allflightdata.priceChange,
  //     adults: allflightdata.adults,
  //     child: allflightdata.child,
  //     infant: allflightdata.infant,
  //     passengerDetails: Merged,
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };
  //   fetch(googlrBaseUrl + "/googleReviewAndBook", requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       setOrderId(result.data.orderId);
  //     })
  //     .catch((error) => {});
  // }

  function Payment_Method() {
    const Modifieddata = {
      ...Merged[0],
      phone: `+${inputList[0].countryCodeNo.split("-")[0]} ${
        inputList[0].phone
      }`,
      country: inputList[0].countryCodeNo.split("-")[1],
      countryCode: inputList[0].countryCodeNo.split("-")[2],
    };

    Merged.shift();

    Merged.unshift(Modifieddata);

    setAgree(true);
    setShow(true);
    const { passengerDetails, ...rest } = allflightdata;
    const obj = { ...rest, passengerDetails: inputList };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json;charset=UTF-8");

    var raw = JSON.stringify({
      source: allflightdata.source,
      gdsType: allflightdata.gdsType,
      onWordFlight: allflightdata.onWordFlight,
      orderId: allflightdata.orderId,
      returnFlight: allflightdata.returnFlight,
      bookingResponse: allflightdata.bookingResponse,
      paymentDetails: allflightdata.paymentDetails,
      domestic: allflightdata.domestic,
      error: allflightdata.error,
      token: allflightdata.token,
      tripType: allflightdata.tripType,
      priceChange: allflightdata.priceChange,
      adults: allflightdata.adults,
      child: allflightdata.child,
      infant: allflightdata.infant,
      passengerDetails: Merged,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(googlrBaseUrl + "/googleReviewAndBook", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("result1234",result);
        setcrmData(result);
        setloadCrm(true);
        paymode.payU ? getPayments(result) : paytmMethod(result);
      })
      .catch((error) => {});
  }
  const paytmMethod = async (result) => {
    const newPrice = Math.max(totalpricedata);
    setShow(true);
    try {
      let { data } = result;
      let { orderId } = data;
      const res = await axios.post(`${paytmApi}/payment/start`, {
        amount: newPrice,
        orderId: orderId,
      });
      if (res.data) {
        navigate("/paytm/paytmpayment", {
          state: res.data,
        });
      }
    } catch (err) {
      console.log(err);
    }
    setShow(false);
  };

  function getPayments(result) {
    // ___________________________________latest________________________________________
    const newPrice = Math.max(totalpricedata);
    var EndPointPayU = PayUAPI;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var urlHandle = `${EndPointPayU}/google/responseHandlerSuccess/`;
    let { data } = result;
    let { orderId } = data;
    var raw = JSON.stringify({
      bookingid: orderId,
      totalamt: newPrice,
      customername: inputList[0].firstName,
      cutomeremail: inputList[0].email,
      customercontact: inputList[0].phone,
      method: cardData,
      failurl: urlHandle,
      paymentType: cardData,
      successurl: urlHandle,
      errurl: urlHandle,
    });
    // totalamt: parseFloat(totalpricedata),

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    var pay_url =
      EndPointPayU + `/payupaymentsGoogle?authCode=${PayU_AuthCode}`;
    fetch(pay_url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let redirectUrl = result.replace(/['"]+/g, "");
        window.location.href = redirectUrl;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // ---------------------------------  Latest working Code end   ----------------------------------------

  useEffect(() => setNoOfTravellers(traveller), []);

  // var findFromcountryCode;
  // var findTocountryCode;
  // var infindFromcountryCode;
  // var infindTocountryCode;

  // if (allflightdata.tripType == 1) {
  const fromAir = flightdata.outBound[0].fromAirport;
  const toAir = flightdata.outBound[flightdata.outBound.length - 1].toAirport;

  const findFromcountryCode = AirPortData.find(
    (item) => item.airportCode === fromAir
  );
  const findTocountryCode = AirPortData.find(
    (item) => item.airportCode === toAir
  );

  // } else {
  //   const fromAir = flightdata.outBound[0].fromAirport;
  //   const toAir = flightdata.outBound[flightdata.outBound.length - 1].toAirport;
  //   const infromAir = flightdata.inBound[0].fromAirport;
  //   const intoAir = flightdata.inBound[flightdata.inBound.length - 1].toAirport;

  //   findFromcountryCode = AirPortData.find(
  //     (item) => item.airportCode === fromAir
  //   );
  //   findTocountryCode = AirPortData.find((item) => item.airportCode === toAir);
  //   infindFromcountryCode = AirPortData.find(
  //     (item) => item.airportCode === infromAir
  //   );
  //   infindTocountryCode = AirPortData.find(
  //     (item) => item.airportCode === intoAir
  //   );
  // }
  return (
    <>
      {/* <button onClick={()=>getPayments()}>Get Payment</button> */}
      {/* test */}
      <div className=" grid grid-cols-1  mt-1">
        {/* top Header */}

        <Collapse in={info}>
          <ValidationForm onSubmit={handleSubmitdetails} ref={formRefs}>
            <div className="grid grid-cols-1 bg-gray-200 mt-6 background-contact inner-button-new">
              <div className="contact-number-bg-info d-flex">
                <span className="text-lg ml-3 font-bold  mt-4 mb-4 font-700">
                  {" "}
                  <i className="fas fa-id-badge "></i>&nbsp; Contact Information
                </span>
              </div>
              {inputList.map((x, i) => {
                return (
                  <div className=" px-4 py-2 row">
                    <div className="col-md-6 col-12 mt-2">
                      <input
                        required
                        type="email"
                        name="email"
                        className=" form-control form-control-contact"
                        placeholder="Enter Your Email"
                        value={x.email}
                        onChange={(e) => handleInputChangedetails(e, i)}
                      />
                    </div>
                    <div className="col-md-2 col-sm-3 col-4 mt-2 pr-0">
                      <select
                        required
                        className="form-control form-control-contact"
                        // onChange={(e) => setUserphoneNoCode(e.target.value)}
                        name="countryCodeNo"
                        onChange={(e) => handleInputChange(e, i)}
                      >
                        <option>IN(91)</option>
                        {countryPhoneCodes.map((item) => {
                          return (
                            <option
                              value={`${item.code}-${item.country}-${item.iso}`}
                            >
                              {item.iso}({item.code})
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-md-4  col-sm-9 col-8 mt-2 pl-2">
                      <input
                        type="text"
                        required
                        pattern="[0-9]*"
                        inputmode="numeric"
                        className=" form-control form-control-contact"
                        placeholder="Enter Your Phone No."
                        name="phone"
                        // value={x.phone}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </div>

                    <div className="grid grid-cols-1 bg-gray-200 mt-6 background-contact inner-button-new">
                      {/* <div className=" contact-number-bg-info d-flex">
              <span className="text-lg ml-3 font-bold  mt-4 mb-4 font-700">
                {" "}
                <i className="fas fa-id-badge "></i> &nbsp; GST Info
              </span>
            </div> */}
                      <div className=" px-3 py-2 row">
                        <div className="GSt w-100 grid grid-cols-1 gap-4 ">
                          <span className="d-flex mt-2">
                            <input
                              type="checkbox"
                              id="gst"
                              className="mr-2 mt-2"
                              name="gst"
                              value={gstnum}
                              checked={gstinfo}
                              onChange={handleChange}
                            />
                            <label for="gst"></label>I have a GST number
                            (Optional)
                          </span>
                        </div>

                        {inputList.map((x, i) => {
                          return (
                            gstinfo && (
                              <div className="col-md-12 mt-4 px-0  ">
                                <div className="row">
                                  <div className="col-lg-6 col-md-6 col-12 mt-sm-2  ">
                                    <lable className="mb-1 font-500">
                                      Registration Number
                                    </lable>
                                    <input
                                      value={gstCompany}
                                      type="0123456789"
                                      className=" form-control form-control-contact"
                                      placeholder="Registration Number"
                                      name="gstNumber"
                                      maxLength={15}
                                      minLength={15}
                                      onChange={(e) =>
                                        handleInputChangeReg(e, i)
                                      }
                                      required="required"
                                    />
                                    <div></div>
                                  </div>

                                  <div className="col-lg-6 col-md-6 col-12  mt-sm-2">
                                    <lable className="mb-1 font-500">
                                      Company Name
                                    </lable>
                                    <input
                                      type="text"
                                      className=" form-control form-control-contact"
                                      placeholder="Company Name"
                                      name="gstCompany"
                                      required="required"
                                      onChange={(e) =>
                                        handleInputChangeReg(e, i)
                                      }
                                    />
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-danger mt-4 ml-4 btn-shivam"
                // onClick={() => saveCustomerDetail()}
                disabled={continueSave}
              >
                Continue Details
              </button>
            </div>
          </ValidationForm>
        </Collapse>

        {/* <Collapse in={gstinfo}>
        <ValidationForm onSubmit={handleSubmitdetails} ref={formRefs}>
        
          
         
          </ValidationForm>
        </Collapse>  */}

        {/* <Collapse in={contact}>
          <div className="grid grid-cols-2  px-10 pb-4 gap-2">
            <div className="text-sm  px-2  font-bold">Mobile No.: {mobile}</div>
            <div className="text-sm  px-2 font-bold">Email : {email}</div>
          </div>
        </Collapse> */}

        <div className="grid_contact">
          <Collapse in={open2}>
            <div>
              <div>
                {inputList.map((item, i) => (
                  <div className="d-flex justify-between ml-20 mr-20 row p_4_custome flex-dir travel-email ">
                    <div className="col-lg-6 col-md-6 col-12 text-sm  px-2  font-700">
                      <i className="fa fa-envelope mr-2"></i> Email :{" "}
                      <span className="text-travomint"> {item.email}</span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 text-sm  px-2  font-700">
                      <i className="fa fa-phone mr-2"></i>Phone No :{" "}
                      <span className="text-travomint">{item.phone}</span>
                    </div>
                  </div>
                ))}
                <div>
                  {inputList.map((item, i) => (
                    <div className="d-flex align-center justify-between   p_4_custome row">
                      <div className="row">
                        <div className="col-lg-12 font-600 border-bottom mb-3 traveller-heading">
                          Adult {i + 1}
                        </div>
                        <div className="row">
                          <div className="text-sm font-bold  col-md-6 col-12 ">
                            <strong>Name :</strong>{" "}
                            <span className="text-travomint font-700">
                              {item.title} {item.firstName} {item.middleName}{" "}
                              {item.lastName}
                            </span>
                          </div>
                          <div className="text-sm font-bold  col-md-6 col-12">
                            <strong>DOB :</strong>
                            <span className="text-travomint font-700">
                              {" "}
                              {item.dob}
                            </span>
                          </div>
                          <div className="text-sm font-bold  col-md-6 col-12">
                            <strong>Gender :</strong>
                            <span className="text-travomint font-700">
                              {" "}
                              {item.gender == "1" ? "MALE" : ""}{" "}
                              {item.gender == "2" ? "FEMALE" : ""}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  {allflightdata.child !== 0 && (
                    <>
                      {childinputList.map((item, i) => (
                        <div className="d-flex align-center justify-between   p_4_custome row">
                          <div className="row">
                            <div className="col-lg-12 font-600 border-bottom mb-3 traveller-heading ">
                              Child{i + 1}
                            </div>
                            <div className="row">
                              <div className="text-sm font-bold  col-md-6 col-12 ">
                                <strong>Name :</strong>{" "}
                                <span className="text-travomint font-700">
                                  {item.title} {item.firstName}{" "}
                                  {item.middleName} {item.lastName}
                                </span>
                              </div>
                              <div className="text-sm font-bold  col-md-6 col-12">
                                <strong>DOB :</strong>
                                <span className="text-travomint font-700">
                                  {" "}
                                  {item.dob}
                                </span>
                              </div>
                              <div className="text-sm font-bold  col-md-6 col-12">
                                <strong>Gender :</strong>
                                <span className="text-travomint font-700">
                                  {" "}
                                  {item.gender == "1" ? "MALE" : ""}{" "}
                                  {item.gender == "2" ? "FEMALE" : ""}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <div>
                  {allflightdata.infant !== 0 && (
                    <>
                      {infantinputList.map((item, i) => (
                        <div className="d-flex align-center justify-between   p_4_custome row">
                          <div className="row">
                            <div className="col-lg-12 font-600 border-bottom mb-3 traveller-heading ">
                              Infent{i + 1}
                            </div>
                            <div className="row">
                              <div className="text-sm font-bold  col-md-6 col-12 ">
                                <strong>Name :</strong>{" "}
                                <span className="text-travomint font-700">
                                  {item.title} {item.firstName}{" "}
                                  {item.middleName} {item.lastName}
                                </span>
                              </div>
                              <div className="text-sm font-bold  col-md-6 col-12">
                                <strong>DOB :</strong>
                                <span className="text-travomint font-700">
                                  {" "}
                                  {item.dob}
                                </span>
                              </div>
                              <div className="text-sm font-bold  col-md-6 col-12">
                                <strong>Gender :</strong>
                                <span className="text-travomint font-700">
                                  {" "}
                                  {item.gender == "1" ? "MALE" : ""}{" "}
                                  {item.gender == "2" ? "FEMALE" : ""}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Collapse>

          <Collapse in={open}>
            <ValidationForm onSubmit={handleSubmit} ref={formRefs}>
              <div className="modal-body">
                <div className="text-danger font-500">
                  Adult passenger should be 12+ Years
                </div>

                {inputList.map((x, i) => {
                  const maxdate = moment().format("YYYY-MM-DD").toString();

                  return (
                    <div className="input-main-one" key={i}>
                      <div className="row form-group mb-1 ">
                        <h5 className="main-heading">Adult {i + 1}</h5>

                        <div className="col-lg-3 col-sm-6 col-md-3 text-input-all">
                          <div>
                            <h5>Title</h5>
                          </div>
                          <div>
                            <select
                              name="title"
                              id="cars"
                              required
                              value={x.title}
                              onChange={(e) => handleInputChange(e, i)}
                              className="form-control form-control-contact font-700"
                            >
                              <option value="">Select Title</option>
                              <option value="MR">Mr</option>
                              <option value="MRS">Mrs</option>
                              <option value="MS">Ms</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-3 col-md-3 text-input-all">
                          <div>
                            <h5>First Name</h5>
                          </div>
                          <TextInput
                            type="text"
                            className="form-control"
                            pattern="[A-Za-z]{2,20}"
                            name="firstName"
                            autoComplete="off"
                            placeholder="First Name"
                            value={x.firstName}
                            onChange={(e) => handleInputChange(e, i)}
                            required
                          />
                        </div>
                        <div className="col-lg-3 col-sm-2 col-md-3 text-input-all">
                          <div>
                            <h5>Middle Name</h5>
                          </div>
                          <TextInput
                            type="text"
                            className="form-control"
                            pattern="[A-Za-z]+"
                            name="middleName"
                            autoComplete="off"
                            value={x.middleName}
                            placeholder="Middle Name"
                            onChange={(e) => handleInputChange(e, i)}
                          />
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 text-input-all">
                          <div>
                            <h5>Last Name</h5>
                          </div>
                          <TextInput
                            type="text"
                            className="form-control"
                            pattern="[A-Za-z]{2,20}"
                            autoComplete="off"
                            name="lastName"
                            placeholder="Enter Last Name"
                            value={x.lastName}
                            onChange={(e) => handleInputChange(e, i)}
                            required
                          />
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 text-input-all">
                          <div>
                            <h5>DOB</h5>
                          </div>
                          <TextInput
                            type="date"
                            className="form-control"
                            min={adultminsDate}
                            max={adultmaxsDate}
                            name="dob"
                            placeholder="Date of birth"
                            onChange={(e) => handleInputChange(e, i)}
                            required
                          />
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 text-input-all">
                          <div>
                            <h5>Gender</h5>
                          </div>
                          <div>
                            <select
                              id="cars"
                              name="gender"
                              placeholder="Gender"
                              required
                              onChange={(e) => handleInputChange(e, i)}
                              className="form-control"
                            >
                              <option value="">Gender</option>
                              <option value={1}>Male</option>
                              <option value={2}>Female</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-12"></div>

                        {(findFromcountryCode.countryCode !== "IN" ||
                          findTocountryCode.countryCode !== "IN") && (
                          <>
                            <div className="col-lg-3 col-sm-3 col-md-3 text-input-all">
                              <div>
                                <h5>PassPort No</h5>
                              </div>
                              <TextInput
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="passportNumber"
                                placeholder="Passport No."
                                value={x.passportNumber}
                                onChange={(e) => handleInputChange(e, i)}
                                required
                              />
                            </div>

                            <div className="col-lg-3 col-sm-3 col-md-3 text-input-all">
                              <div>
                                <h5>Issue Date </h5>
                              </div>
                              <TextInput
                                type="date"
                                className="form-control"
                                autoComplete="off"
                                name="passportIssued"
                                placeholder="Issue Date"
                                value={x.passportIssued}
                                onChange={(e) => handleInputChange(e, i)}
                                required
                              />
                            </div>

                            <div className="col-lg-3 col-sm-3 col-md-3 text-input-all">
                              <div>
                                <h5>Expiry Date </h5>
                              </div>
                              <TextInput
                                type="date"
                                className="form-control"
                                autoComplete="off"
                                name="passportExpiry"
                                placeholder="Expiry Date"
                                value={x.passportExpiry}
                                onChange={(e) => handleInputChange(e, i)}
                                required
                              />
                            </div>

                            <div className="col-lg-3 col-sm-3 col-md-3 text-input-all">
                              <div>
                                <h5>Country</h5>
                              </div>
                              <TextInput
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="passportIssuedCountry"
                                placeholder="Country"
                                value={x.passportIssuedCountry}
                                onChange={(e) => handleInputChange(e, i)}
                                required
                              />
                            </div>
                          </>
                        )}
                        <Collapse>
                          <div
                            id="example-collapse-text"
                            className="pt-4 bg-white"
                          >
                            {x.addBagage != "" ? (
                              <>
                                <div className="grid grid-cols-2 gap-4 px-24 ">
                                  <div className="grid grid-cols-8 ">
                                    <div className="col-span-1 px-1">
                                      <TextInput
                                        type="radio"
                                        id="five"
                                        name="weight"
                                        value="5kg"
                                        onClick={(e) => handleInputChange(e, i)}
                                        className="h-4 w-4 mt-3 hover:bg-gray-400"
                                      />
                                    </div>
                                    <div className="py-2">
                                      <i className="fas fa-briefcase fa-lg text-gray-600"></i>
                                    </div>
                                    <div className="col-span-6 py-1 ">
                                      <p className="text-xs mb-0 font-bold text-orange-600">
                                        Additional 5KG
                                      </p>
                                      <p className="text-xl text-black font-bold">
                                        <i className="fas fa-rupee-sign"></i>{" "}
                                        2000
                                      </p>
                                    </div>
                                  </div>

                                  <div className="grid grid-cols-8">
                                    <div className="col-span-1">
                                      <TextInput
                                        type="radio"
                                        id="ten"
                                        name="weight"
                                        value="10kg"
                                        onClick={(e) => handleInputChange(e, i)}
                                        className="h-4 w-4 mt-3 hover:bg-gray-400"
                                      />
                                    </div>
                                    <div className="py-2">
                                      <i className="fas fa-briefcase fa-lg text-gray-600"></i>
                                    </div>
                                    <div className="col-span-6 py-1 ">
                                      <p className="text-xs mb-0 font-bold text-orange-600">
                                        Additional 10KG
                                      </p>
                                      <p className="text-xl text-black font-bold">
                                        <i className="fas fa-rupee-sign"></i>{" "}
                                        2000
                                      </p>
                                    </div>
                                  </div>

                                  <div className="grid grid-cols-8">
                                    <div className="col-span-1">
                                      <TextInput
                                        type="radio"
                                        id="fifteen"
                                        name="weight"
                                        value="15kg"
                                        onClick={(e) => handleInputChange(e, i)}
                                        className="h-4 w-4 mt-3 hover:bg-gray-400"
                                      />
                                    </div>
                                    <div className="py-2">
                                      <i className="fas fa-briefcase fa-lg text-gray-600"></i>
                                    </div>
                                    <div className="col-span-6 py-1 ">
                                      <p className="text-xs mb-0 font-bold text-orange-600">
                                        Additional 20KG
                                      </p>
                                      <p className="text-xl text-black font-bold">
                                        <i className="fas fa-rupee-sign"></i>{" "}
                                        2000
                                      </p>
                                    </div>
                                  </div>

                                  <div className="grid grid-cols-8">
                                    <div className="col-span-1">
                                      <TextInput
                                        type="radio"
                                        id="twentyfive"
                                        name="weight"
                                        value="25kg"
                                        onClick={(e) => handleInputChange(e, i)}
                                        className="h-4 w-4 mt-3 hover:bg-gray-400"
                                      />
                                    </div>
                                    <div className="py-2">
                                      <i className="fas fa-briefcase fa-lg text-gray-600"></i>
                                    </div>
                                    <div className="col-span-6 py-1 ">
                                      <p className="text-xs mb-0 font-bold text-orange-600">
                                        Additional 25KG
                                      </p>
                                      <p className="text-xl text-black font-bold">
                                        <i className="fas fa-rupee-sign"></i>{" "}
                                        2000
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="grid grid-cols-1 align-items-right px-10">
                                  <div>
                                    {" "}
                                    <button
                                      className="btn w-full btn-shivam add-bagger "
                                      name="addBagage"
                                      value=""
                                      onClick={(e) => Done(e, i)}
                                    >
                                      Done
                                    </button>
                                  </div>
                                </div>
                              </>
                            ) : (
                              " "
                            )}
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  );
                })}
                <div className="grid grid-cols-1 mt-4 flexEnd">
                  {Adult_traveller === 1 ? null : (
                    <>
                      {inputList.length !== Adult_traveller && (
                        <div className="col-lg-2 col-sm-1 col-md-1 align-self-center">
                          <button
                            className=" btn-primary btn-sm mt-1 mt-md-1"
                            onClick={handleAddClick}
                          >
                            Add Traveller
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              {Child_traveller !== 0 && (
                <>
                  <div className="modal-body">
                    <div className="text-danger font-500">
                      Child passenger should be 2-12 Years
                    </div>
                    {childinputList.map((x, i) => {
                      const maxdate = moment().format("YYYY-MM-DD").toString();

                      return (
                        <div className="input-main-one" key={i}>
                          <div className="row form-group mb-1 ">
                            <h5 className="main-heading">Child {i + 1}</h5>

                            <div className="col-lg-3 col-sm-6 col-md-3 text-input-all">
                              <div>
                                <h5>Title</h5>
                              </div>
                              <div>
                                <select
                                  name="title"
                                  id="cars"
                                  required
                                  value={x.title}
                                  onChange={(e) => childhandleInputChange(e, i)}
                                  className="form-control form-control-contact font-700"
                                >
                                  <option value="">Select Title</option>
                                  <option value="MSTR">Mstr</option>
                                  <option value="MISS">Miss</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-lg-3 col-sm-2 col-md-3 text-input-all">
                              <div>
                                <h5>First Name</h5>
                              </div>
                              <TextInput
                                type="text"
                                className="form-control "
                                name="firstName"
                                pattern="[A-Za-z]{2,20}"
                                autoComplete="off"
                                placeholder="First Name"
                                value={x.firstName}
                                onChange={(e) => childhandleInputChange(e, i)}
                                required
                              />
                            </div>

                            <div className="col-lg-3 col-sm-2 col-md-3 text-input-all">
                              <div>
                                <h5>Middle Name</h5>
                              </div>
                              <TextInput
                                type="text"
                                className="form-control"
                                name="middleName"
                                autoComplete="off"
                                pattern="[A-Za-z]+"
                                value={x.middleName}
                                placeholder="Middle Name"
                                onChange={(e) => childhandleInputChange(e, i)}
                              />
                            </div>
                            <div className="col-lg-3 col-sm-2 col-md-3 text-input-all">
                              <div>
                                <h5>Last Name</h5>
                              </div>
                              <TextInput
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                pattern="[A-Za-z]{2,20}"
                                name="lastName"
                                placeholder="Enter Last Name"
                                value={x.lastName}
                                onChange={(e) => childhandleInputChange(e, i)}
                                required
                              />
                            </div>

                            <div className="col-lg-3 col-sm-2 col-md-3 text-input-all">
                              <div>
                                <h5>DOB</h5>
                              </div>
                              <TextInput
                                type="date"
                                className="form-control"
                                min={childminsDate}
                                max={childmaxsDate}
                                name="dob"
                                onChange={(e) => childhandleInputChange(e, i)}
                                placeholder="Date of birth"
                              />
                            </div>

                            <div className="col-lg-3 col-sm-2 col-md-3 text-input-all">
                              <div>
                                <h5>Gender</h5>
                              </div>
                              <div>
                                <select
                                  id="cars"
                                  name="gender"
                                  placeholder="Gender"
                                  required
                                  onChange={(e) => childhandleInputChange(e, i)}
                                  className="form-control"
                                >
                                  <option value="">Gender</option>
                                  <option value={1}>Male</option>
                                  <option value={2}>Female</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-12"></div>

                            {(findFromcountryCode.countryCode !== "IN" ||
                              findTocountryCode.countryCode !== "IN") && (
                              <>
                                <div className="col-lg-3 col-sm-3 col-md-3 text-input-all">
                                  <div>
                                    <h5>PassPort No</h5>
                                  </div>
                                  <TextInput
                                    type="text"
                                    className="form-control"
                                    autoComplete="off"
                                    name="passportNumber"
                                    placeholder="Passport No."
                                    value={x.passportNumber}
                                    onChange={(e) =>
                                      childhandleInputChange(e, i)
                                    }
                                    required
                                  />
                                </div>

                                <div className="col-lg-3 col-sm-3 col-md-3 text-input-all">
                                  <div>
                                    <h5>Issue Date </h5>
                                  </div>
                                  <TextInput
                                    type="date"
                                    className="form-control"
                                    autoComplete="off"
                                    name="passportIssued"
                                    placeholder="Issue Date"
                                    value={x.passportIssued}
                                    onChange={(e) =>
                                      childhandleInputChange(e, i)
                                    }
                                    required
                                  />
                                </div>
                                <div className="col-lg-3 col-sm-3 col-md-3 text-input-all">
                                  <div>
                                    <h5>Expiry Date </h5>
                                  </div>
                                  <TextInput
                                    type="date"
                                    className="form-control"
                                    autoComplete="off"
                                    name="passportExpiry"
                                    placeholder="Expiry Date"
                                    value={x.passportExpiry}
                                    onChange={(e) =>
                                      childhandleInputChange(e, i)
                                    }
                                    required
                                  />
                                </div>
                                <div className="col-lg-3 col-sm-3 col-md-3 text-input-all">
                                  <div>
                                    <h5>Country</h5>
                                  </div>
                                  <TextInput
                                    type="text"
                                    className="form-control"
                                    autoComplete="off"
                                    name="passportIssuedCountry"
                                    placeholder="Country"
                                    value={x.passportIssuedCountry}
                                    onChange={(e) =>
                                      childhandleInputChange(e, i)
                                    }
                                    required
                                  />
                                </div>
                              </>
                            )}

                            {/* baggage Body */}
                            {/* <Collapse>
<div
id="example-collapse-text"
className="pt-4 bg-white"
>
{x.addBagage != "" ? (
<>
<div className="grid grid-cols-2 gap-4 px-24 ">
<div className="grid grid-cols-8 ">
<div className="col-span-1 px-1">
<TextInput
type="radio"
id="five"
name="weight"
value="5kg"
onClick={(e) =>
handleInputChange(e, i)
}
className="h-4 w-4 mt-3 hover:bg-gray-400"
/>
</div>
<div className="py-2">
<i className="fas fa-briefcase fa-lg text-gray-600"></i>
</div>
<div className="col-span-6 py-1 ">
<p className="text-xs mb-0 font-bold text-orange-600">
Additional 5KG
</p>
<p className="text-xl text-black font-bold">
<i className="fas fa-rupee-sign"></i>{" "}
2000
</p>
</div>
</div>

<div className="grid grid-cols-8">
<div className="col-span-1">
<TextInput
type="radio"
id="ten"
name="weight"
value="10kg"
onClick={(e) =>
handleInputChange(e, i)
}
className="h-4 w-4 mt-3 hover:bg-gray-400"
/>
</div>
<div className="py-2">
<i className="fas fa-briefcase fa-lg text-gray-600"></i>
</div>
<div className="col-span-6 py-1 ">
<p className="text-xs mb-0 font-bold text-orange-600">
Additional 10KG
</p>
<p className="text-xl text-black font-bold">
<i className="fas fa-rupee-sign"></i>{" "}
2000
</p>
</div>
</div>

<div className="grid grid-cols-8">
<div className="col-span-1">
<TextInput
type="radio"
id="fifteen"
name="weight"
value="15kg"
onClick={(e) =>
handleInputChange(e, i)
}
className="h-4 w-4 mt-3 hover:bg-gray-400"
/>
</div>
<div className="py-2">
<i className="fas fa-briefcase fa-lg text-gray-600"></i>
</div>
<div className="col-span-6 py-1 ">
<p className="text-xs mb-0 font-bold text-orange-600">
Additional 20KG
</p>
<p className="text-xl text-black font-bold">
<i className="fas fa-rupee-sign"></i>{" "}
2000
</p>
</div>
</div>

<div className="grid grid-cols-8">
<div className="col-span-1">
<TextInput
type="radio"
id="twentyfive"
name="weight"
value="25kg"
onClick={(e) =>
handleInputChange(e, i)
}
className="h-4 w-4 mt-3 hover:bg-gray-400"
/>
</div>
<div className="py-2">
<i className="fas fa-briefcase fa-lg text-gray-600"></i>
</div>
<div className="col-span-6 py-1 ">
<p className="text-xs mb-0 font-bold text-orange-600">
Additional 25KG
</p>
<p className="text-xl text-black font-bold">
<i className="fas fa-rupee-sign"></i>{" "}
2000
</p>
</div>
</div>
</div>
<div className="grid grid-cols-1 align-items-right px-10">
<div>
{" "}
<button
className="btn w-full btn-shivam add-bagger "
name="addBagage"
value=""
onClick={(e) => Done(e, i)}
>
Done
</button>
</div>
</div>
</>
) : (
" "
)}
</div>
</Collapse> */}

                            {/* baggage body over */}
                            {/* {noOfTravellers != 1 && (
<div className="col-lg-1 col-sm-1 col-md-1 align-self-center">
{inputList.length - 1 === index && (
<button
className="btn btn-primary btn-sm mt-1 mt-md-0"
onClick={handleAddClick}
>
Add
<i className="fa fa-plus"></i>
</button>
)}
</div>
)} */}
                          </div>
                        </div>
                      );
                    })}

                    <div className="grid grid-cols-1 mt-4 flexEnd">
                      {Child_traveller === 0 || Child_traveller === 1 ? null : (
                        <>
                          {childinputList.length === Child_traveller ? null : (
                            <div className="col-lg-2 col-sm-1 col-md-1 align-self-center">
                              <button
                                className=" btn-primary btn-sm mt-1 mt-md-1"
                                onClick={childhandleAddClick}
                              >
                                Add Traveller
                              </button>
                            </div>
                          )}
                        </>
                      )}

                      {/* <div className="px-5 ">
<button
className="btn btn-shivam float-right add-bagger"
name="addBagage"
value="addBagage"
onClick={(e) => handleInputChange(e, i)}
onFocus={(e) => close1(e, i)}
type="submit"
>
<i className="fas fa-briefcase "></i> &nbsp; Add Baggage
</button>
</div> */}
                    </div>
                  </div>

                  {/* add Baggage */}
                </>
              )}
              {/* Infant */}
              {/* child */}
              {Infant_traveller !== 0 && (
                <>
                  <div className="modal-body">
                    <div className="text-danger font-500">
                      Infent passenger should be Max 2year
                    </div>
                    {infantinputList.map((x, i) => {
                      const maxdate = moment().format("YYYY-MM-D D").toString();

                      return (
                        <div className="input-main-one" key={i}>
                          <div className="row form-group mb-1 ">
                            <h5 className="main-heading">Infant {i + 1}</h5>
                            {/* <div className="col-lg-3 col-sm-3 col-md-3 text-input-all">
{passengerValues.map((x, i) => {
return (
<>
<div>
<h5>{x.name}</h5>
</div>
<TextInput
type="radio"
name="passengerType"
value={x.value}
onChange={(e) => infanthandleInputChange(e, i)}
required="required"
/>
</>
);
})}
</div> */}
                            <div className="col-lg-3 col-sm-6 col-md-3 text-input-all">
                              <div>
                                <h5>Title</h5>
                              </div>
                              <div>
                                <select
                                  name="title"
                                  id="cars"
                                  required
                                  value={x.title}
                                  onChange={(e) =>
                                    infanthandleInputChange(e, i)
                                  }
                                  className="form-control form-control-contact font-700"
                                >
                                  <option value="">Select Title</option>
                                  <option value="MSTR">Mstr</option>
                                  <option value="MISS">Miss</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-lg-3 col-sm-2 col-md-3 text-input-all">
                              <div>
                                <h5>First Name</h5>
                              </div>
                              <TextInput
                                type="text"
                                className="form-control "
                                name="firstName"
                                autoComplete="off"
                                pattern="[A-Za-z]{2,20}"
                                placeholder="First Name"
                                value={x.firstName}
                                onChange={(e) => infanthandleInputChange(e, i)}
                                required
                              />
                            </div>

                            <div className="col-lg-3 col-sm-2 col-md-3 text-input-all">
                              <div>
                                <h5>Middle Name</h5>
                              </div>
                              <TextInput
                                type="text"
                                className="form-control"
                                name="middleName"
                                pattern="[A-Za-z]+"
                                autoComplete="off"
                                value={x.middleName}
                                placeholder="Middle Name"
                                onChange={(e) => infanthandleInputChange(e, i)}
                              />
                            </div>
                            <div className="col-lg-3 col-sm-2 col-md-3 text-input-all">
                              <div>
                                <h5>Last Name</h5>
                              </div>
                              <TextInput
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                pattern="[A-Za-z]{2,20}"
                                name="lastName"
                                placeholder="Enter Last Name"
                                value={x.lastName}
                                onChange={(e) => infanthandleInputChange(e, i)}
                                required
                              />
                            </div>

                            <div className="col-lg-3 col-sm-2 col-md-3 text-input-all">
                              <div>
                                <h5>DOB</h5>
                              </div>
                              <TextInput
                                type="date"
                                className="form-control"
                                name="dob"
                                max={InfantmaxsDate}
                                min={InfantminsDate}
                                placeholder="Date of birth"
                                onChange={(e) => infanthandleInputChange(e, i)}
                                required
                              />
                            </div>

                            <div className="col-lg-3 col-sm-2 col-md-3 text-input-all">
                              <div>
                                <h5>Gender</h5>
                              </div>
                              <div>
                                <select
                                  id="cars"
                                  name="gender"
                                  placeholder="Gender"
                                  required
                                  onChange={(e) =>
                                    infanthandleInputChange(e, i)
                                  }
                                  className="form-control"
                                >
                                  <option value="">Gender</option>
                                  <option value={1}>Male</option>
                                  <option value={2}>Female</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-12"></div>

                            {(findFromcountryCode.countryCode !== "IN" ||
                              findTocountryCode.countryCode !== "IN") && (
                              <>
                                <div className="col-lg-3 col-sm-3 col-md-3 text-input-all">
                                  <div>
                                    <h5>PassPort No</h5>
                                  </div>
                                  <TextInput
                                    type="text"
                                    className="form-control"
                                    autoComplete="off"
                                    name="passportNumber"
                                    placeholder="Passport No."
                                    value={x.passportNumber}
                                    onChange={(e) =>
                                      infanthandleInputChange(e, i)
                                    }
                                    required
                                  />
                                </div>
                                <div className="col-lg-3 col-sm-3 col-md-3 text-input-all">
                                  <div>
                                    <h5>Issue Date </h5>
                                  </div>
                                  <TextInput
                                    type="date"
                                    className="form-control"
                                    autoComplete="off"
                                    name="passportIssued"
                                    placeholder="Issue Date"
                                    value={x.passportIssued}
                                    onChange={(e) =>
                                      infanthandleInputChange(e, i)
                                    }
                                    required
                                  />
                                </div>

                                <div className="col-lg-3 col-sm-3 col-md-3 text-input-all">
                                  <div>
                                    <h5>Expiry Date </h5>
                                  </div>
                                  <TextInput
                                    type="date"
                                    className="form-control"
                                    autoComplete="off"
                                    name="passportExpiry"
                                    placeholder="Issue Date"
                                    value={x.passportExpiry}
                                    onChange={(e) =>
                                      infanthandleInputChange(e, i)
                                    }
                                    required
                                  />
                                </div>
                                <div className="col-lg-3 col-sm-3 col-md-3 text-input-all">
                                  <div>
                                    <h5>Country</h5>
                                  </div>
                                  <TextInput
                                    type="text"
                                    className="form-control"
                                    autoComplete="off"
                                    name="passportIssuedCountry"
                                    placeholder="Country"
                                    value={x.passportIssuedCountry}
                                    onChange={(e) =>
                                      infanthandleInputChange(e, i)
                                    }
                                    required
                                  />
                                </div>
                              </>
                            )}

                            {/* baggage Body */}
                            <Collapse>
                              <div
                                id="example-collapse-text"
                                className="pt-4 bg-white"
                              >
                                {x.addBagage != "" ? (
                                  <>
                                    <div className="grid grid-cols-2 gap-4 px-24 ">
                                      <div className="grid grid-cols-8 ">
                                        <div className="col-span-1 px-1">
                                          <TextInput
                                            type="radio"
                                            id="five"
                                            name="weight"
                                            value="5kg"
                                            onClick={(e) =>
                                              handleInputChange(e, i)
                                            }
                                            className="h-4 w-4 mt-3 hover:bg-gray-400"
                                          />
                                        </div>
                                        <div className="py-2">
                                          <i className="fas fa-briefcase fa-lg text-gray-600"></i>
                                        </div>
                                        <div className="col-span-6 py-1 ">
                                          <p className="text-xs mb-0 font-bold text-orange-600">
                                            Additional 5KG
                                          </p>
                                          <p className="text-xl text-black font-bold">
                                            <i className="fas fa-rupee-sign"></i>{" "}
                                            2000
                                          </p>
                                        </div>
                                      </div>

                                      <div className="grid grid-cols-8">
                                        <div className="col-span-1">
                                          <TextInput
                                            type="radio"
                                            id="ten"
                                            name="weight"
                                            value="10kg"
                                            onClick={(e) =>
                                              handleInputChange(e, i)
                                            }
                                            className="h-4 w-4 mt-3 hover:bg-gray-400"
                                          />
                                        </div>
                                        <div className="py-2">
                                          <i className="fas fa-briefcase fa-lg text-gray-600"></i>
                                        </div>
                                        <div className="col-span-6 py-1 ">
                                          <p className="text-xs mb-0 font-bold text-orange-600">
                                            Additional 10KG
                                          </p>
                                          <p className="text-xl text-black font-bold">
                                            <i className="fas fa-rupee-sign"></i>{" "}
                                            2000
                                          </p>
                                        </div>
                                      </div>

                                      <div className="grid grid-cols-8">
                                        <div className="col-span-1">
                                          <TextInput
                                            type="radio"
                                            id="fifteen"
                                            name="weight"
                                            value="15kg"
                                            onClick={(e) =>
                                              handleInputChange(e, i)
                                            }
                                            className="h-4 w-4 mt-3 hover:bg-gray-400"
                                          />
                                        </div>
                                        <div className="py-2">
                                          <i className="fas fa-briefcase fa-lg text-gray-600"></i>
                                        </div>
                                        <div className="col-span-6 py-1 ">
                                          <p className="text-xs mb-0 font-bold text-orange-600">
                                            Additional 20KG
                                          </p>
                                          <p className="text-xl text-black font-bold">
                                            <i className="fas fa-rupee-sign"></i>{" "}
                                            2000
                                          </p>
                                        </div>
                                      </div>

                                      <div className="grid grid-cols-8">
                                        <div className="col-span-1">
                                          <TextInput
                                            type="radio"
                                            id="twentyfive"
                                            name="weight"
                                            value="25kg"
                                            onClick={(e) =>
                                              handleInputChange(e, i)
                                            }
                                            className="h-4 w-4 mt-3 hover:bg-gray-400"
                                          />
                                        </div>
                                        <div className="py-2">
                                          <i className="fas fa-briefcase fa-lg text-gray-600"></i>
                                        </div>
                                        <div className="col-span-6 py-1 ">
                                          <p className="text-xs mb-0 font-bold text-orange-600">
                                            Additional 25KG
                                          </p>
                                          <p className="text-xl text-black font-bold">
                                            <i className="fas fa-rupee-sign"></i>{" "}
                                            2000
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="grid grid-cols-1 align-items-right px-10">
                                      <div>
                                        {" "}
                                        <button
                                          className="btn w-full btn-shivam add-bagger "
                                          name="addBagage"
                                          value=""
                                          onClick={(e) => Done(e, i)}
                                        >
                                          Done
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  " "
                                )}
                              </div>
                            </Collapse>

                            {/* baggage body over */}
                            {/* {noOfTravellers != 1 && (
<div className="col-lg-1 col-sm-1 col-md-1 align-self-center">
{inputList.length - 1 === index && (
<button
className="btn btn-primary btn-sm mt-1 mt-md-0"
onClick={handleAddClick}
>
Add
<i className="fa fa-plus"></i>
</button>
)}
</div>
)} */}
                          </div>
                        </div>
                      );
                    })}
                    <div className="grid grid-cols-1 mt-4 flexEnd">
                      {Infant_traveller === 0 ||
                      Infant_traveller === 1 ? null : (
                        <>
                          {infantinputList.length !== Infant_traveller && (
                            <div className="col-lg-2 col-sm-1 col-md-1 align-self-center">
                              <button
                                className=" btn-primary btn-sm mt-1 mt-md-1"
                                onClick={infanthandleAddClick}
                              >
                                Add Traveller
                              </button>
                            </div>
                          )}
                        </>
                      )}

                      {/* <div className="px-5 ">
<button
className="btn btn-shivam float-right add-bagger"
name="addBagage"
value="addBagage"
onClick={(e) => handleInputChange(e, i)}
onFocus={(e) => close1(e, i)}
type="submit"
>
<i className="fas fa-briefcase "></i> &nbsp; Add Baggage
</button>
</div> */}
                    </div>
                  </div>
                </>
              )}

              <div className="modal-footer">
                {Merged.length === traveller && (
                  <button
                    type="submit"
                    className="btn btn-danger"
                    disabled={ispricechk}
                    // onClick={() => saveCustomerDetail()}
                  >
                    Continue
                  </button>
                )}
              </div>
            </ValidationForm>
          </Collapse>
        </div>

        <Collapse in={address} className="hidden">
          <ValidationForm onSubmit={handleSubmitnew} ref={formRefs}>
            {addressList.map((x, i) => {
              return (
                <div className="px-4">
                  <h4 className="mt-4 mb-4">Details</h4>

                  <div className="row">
                    <div className="address1 col-md-6 col-6 col-sm-12 mt-2">
                      <h6>Address 1</h6>
                      <div className="col-md-12 px-0">
                        <div className="col-md-12 px-0 mt-2">
                          <TextInput
                            type="text"
                            className="form-control form-control-contact"
                            name="firstName"
                            autoComplete="off"
                            placeholder="Address 1"
                            value={x.address1}
                            onChange={(e) => handleInputChangenew(e, i)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="address1 col-md-6 col-6 col-sm-12 mt-2">
                      <h6>Address 2</h6>
                      <div className="col-md-12 px-0">
                        <div className="col-md-12 px-0 mt-2">
                          <TextInput
                            type="text"
                            className="form-control form-control-contact"
                            name="firstName"
                            autoComplete="off"
                            placeholder="Address 2"
                            value={x.address2}
                            onChange={(e) => handleInputChangenew(e, i)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="address1 col-md-6 col-6 col-sm-12 mt-2">
                      <h6>City</h6>
                      <div className="col-md-12 px-0">
                        <div className="col-md-12 px-0 mt-2">
                          <TextInput
                            type="text"
                            className="form-control form-control-contact"
                            name="firstName"
                            autoComplete="off"
                            placeholder="City"
                            value={x.city}
                            onChange={(e) => handleInputChangenew(e, i)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="address1 col-md-6 col-6 col-sm-12 mt-2">
                      <h6>State</h6>
                      <div className="col-md-12 px-0 ">
                        <div className="col-md-12 px-0 mt-2">
                          <TextInput
                            type="text"
                            className="form-control form-control-contact"
                            name="firstName"
                            autoComplete="off"
                            placeholder="State"
                            value={x.state}
                            onChange={(e) => handleInputChangenew(e, i)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="address1 col-md-6 col-6 col-sm-12 mt-2">
                      <h6>Country</h6>
                      <div className="col-md-12 px-0 ">
                        <div className="col-md-12 mt-2 px-0 mt-2 ">
                          <TextInput
                            type="text"
                            className="form-control form-control-contact"
                            name="firstName"
                            autoComplete="off"
                            placeholder="Country"
                            value={x.country}
                            onChange={(e) => handleInputChangenew(e, i)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="address1 col-md-6 col-6 col-sm-12 mt-2">
                      <h6>zipcode</h6>
                      <div className="col-md-12 px-0 ">
                        <div className="col-md-12 px-0 mt-2">
                          <TextInput
                            type="text"
                            className="form-control form-control-contact"
                            name="firstName"
                            autoComplete="off"
                            placeholder="Zipcode"
                            value={x.zipcode}
                            onChange={(e) => handleInputChangenew(e, i)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            <button type="submit" className="btn btn-danger mt-4 ml-4">
              Submit Details
            </button>
          </ValidationForm>
        </Collapse>

        <Collapse className="mt-4" in={pay}>
          <div>
            <div className=" rounded-2xl pl-0 text-left font-bold text-xl text-black">
              <h6>
                <i className="fas fa-payment "></i>*Please Select Payment Mode{" "}
              </h6>
            </div>
            <div className=" row">
              {/* <div className="left-panal-payment col-lg-4 col-md-4 ">
                <form>
                  <div className="plans">
                    <label className="plan basic-plan" for="basic">
                      <input checked type="radio" name="plan" id="basic" />
                      <div className="plan-content">
                        <i className="fas fa-mobile-alt icon-mobile-pay"></i>
                        <div className="plan-details">
                          <h3 className="header-t">UPI</h3>
                        </div>
                      </div>
                    </label>
                  </div>
                </form>
              </div> */}
              <div className="flex items-center gap-16 pl-4">
                <div
                  className="flex items-center cursor-pointer ml-2"
                  onClick={() => setPayMode({ payU: true, paytm: false })}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="paymode"
                    checked={paymode.payU}
                    required
                  />
                  <img
                    src="/Images/payu-icon.svg"
                    alt="PayU"
                    srcset=""
                    className="w-20 h-9"
                  />
                </div>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => setPayMode({ paytm: true, payU: false })}
                >
                  <input
                    className="form-check-input"
                    name="paytm"
                    type="radio"
                    checked={paymode.paytm}
                    required
                  />
                  <img
                    src="/Images/paytm-icon.svg"
                    alt="Paytm"
                    srcset=""
                    className="w-20 h-10"
                  />
                </div>
              </div>
              {paymode.payU && (
                <div className="left-panal-payment col-lg-4 col-md-4 mt-4">
                  <form>
                    <div className="plans">
                      {payUMethodList.map((item) => {
                        return (
                          <label
                            className="plan basic-plan"
                            for={item.value}
                            onClick={() => setcardData(item.value)}
                          >
                            <input
                              type="radio"
                              name="plan"
                              id={item.value}
                              defaultChecked={cardData === item.value}
                            />
                            <div className="plan-content">
                              <i className="fas fa-money-check"></i>
                              <div className="plan-details">
                                <h3>{item.name}</h3>
                              </div>
                            </div>
                          </label>
                        );
                      })}
                    </div>
                  </form>
                </div>
              )}

              <div className="col-lg-8 col-md-8 px-4 mt-4">
                <div className="form-group  payment-details">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="iagree"
                    value="false"
                    required
                    onClick={() => setAgree(false)}
                  />
                  <label className="form-check-label" for="iagree">
                    I AGREE
                  </label>
                  <span className="text-sm">
                    {""}
                    &nbsp; By clicking Pay Now, I agree that I have read and
                    accepted travomint.com andTerms and Conditions Privacy
                    Policy{""}
                    <p className="text-xs">
                      Please confirm that the names and spelling of travelers
                      are correct & accurate. Please confirm that the dates and
                      timing of flight departures are correct. Tickets are
                      non-transferable as well as non-refundable. Name changes
                      on tickets are not permitted. Our service fees are
                      non-refundable. Total ticket cost included all the taxes
                      and service fees. Date and routing changes will be subject
                      to airline penalties and our service fees. Fares are not
                      guaranteed until ticketed.
                    </p>
                  </span>
                </div>

                <div className="px-2 pb-2 d-flex align-items-sm-center payment-details-paynow flex-sm-row">
                  <span className="text-blue-600 -top-2 relative text-3xl font-700 text-size-lg ">
                    <i className="fas fa-rupee-sign"></i>
                    {totalpricedata}/-
                  </span>

                  <button
                    disabled={agree}
                    className="btn btn-primary"
                    onClick={() => Payment_Method()}
                  >
                    Proceed to Pay
                  </button>

                  <Modal
                    show={show}
                    onHide={handleClose}
                    centered
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Body>
                      <img src={loaderimage} />
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};
export default Second;
const payUMethodList = [
  { id: 1, name: "UPI", value: "payuupi" },
  { id: 2, name: "Debit Card", value: "payudbc" },
  { id: 3, name: "Credit Card", value: "payuccd" },
  { id: 4, name: "Wallet", value: "payuwallet" },
  { id: 5, name: "Net Banking", value: "payunbc" },
];
