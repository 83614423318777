import { combineReducers } from "redux";
import CurrencyReducer from "../CurrencyReducer";
import dataReducer from "../../../Feature/Action.js";
import { persistStore, persistReducer } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";
import SkipValueReducer from "../SkipValueReducer";

const presistConfig = {
  key: "root",
  storage: AsyncStorage,
};

export const RootReducer = combineReducers({
  currency_Reducer: persistReducer(presistConfig, CurrencyReducer),
  data: persistReducer(presistConfig,dataReducer),
  SkipValue_Reducer: SkipValueReducer,
});
