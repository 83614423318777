import React from "react";
import PaymnetBanner from "../../Image/Banner/PaymnetBanner.png";
import Travomint_Rating_Banner from "../../Image/Banner/Travomint_Rating_Banner.png";
import playstore from "../../Image/Banner/playstorelight.png";
import appstore from "../../Image/Banner/appstorelight.png";

const SideBanner = () => {
  return (
    <div className="ratingapp-box mt-4">
      <img src={Travomint_Rating_Banner} class="rounded-xl " />
      <hr></hr>
      <div className="bg-white rounded-xl py-2 ">
      <img src={PaymnetBanner}/>
      </div>

      <hr></hr>
      <div className="row align-items-end">
        <div className="col-6">
          <a
            href="https://play.google.com/store/apps/details?id=com.snva.TravoMint&hl=en&gl=GB"
            target="_blank"
            className="floatt-left w-100"
          >
            <img src={playstore} className="w-100 rounded-sm" />
          </a>
        </div>

        <div className="col-6">
          <a
            href="https://apps.apple.com/in/app/travomint/id1603093439"
            target="_blank"
            className="floatt-left w-100"
          >
            <img src={appstore} className="w-100 rounded-sm appstore-55" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SideBanner;
