import React from "react";
import logo from "../Image/logo.png";
import cardIn from "../Image/cardIn-logos.png";
import godaddy from "../Image/godaddy.gif";
import footerBG from "../Image/footer-bg2.png";

const Footer = () => {
  return (
    <>
      <div className=" text-black travomint-bg ">
        <span className="text-2xl font-bold">Why Travomint</span>
        <p className="text-sm font-medium">
          Travomint.com is an independent travel portal with no third party
          association. By using www.travomint.com, you agree that Travomint is
          not accountable for any loss - direct or indirect, arising of offers,
          materials or links to other sites found on this website. In case of
          queries, reach us directly at our Toll Free{" "}
        </p>
        <br />
      </div>

      <div className="row  pb-4 footer footer-set">
        <div className="col-md-12 col-12 text-center">
          <p className="text-light ">
            <b>Number-</b> +91-1204814491 or, simply <b>Email</b> at
            cs@travomint.com
          </p>
          <hr />
          <div className="container">
            <div className=" row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-12 text-light  ">
                <span className="copyright-small">
                  <img src={logo} className="img-fluid" /> Copyright © 2021. All
                  rights reseved.
                </span>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                <img src={cardIn} className="w-1/2 inline-block" /> &nbsp;
                <img src={godaddy} className="w-1/3 inline-block" />
                <br />
                <span className="copyright-small text-light">
                  We assure safe and secure transactions through powerful
                  Godaddy Secure Seal
                </span>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                <span className="copyright-small text-light">
                  {" "}
                  (DBA of SNVA Traveltech Pvt Ltd)
                </span>
              </div>
            </div>
            <hr></hr>
          </div>

          <div className="container footer-bottom-pd">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-12 pt-2">
                <h4 className="text-light font-weight-bold text-left">
                  WE ARE GLAD TO ASSIST YOU:
                </h4>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-12 pt-2">
                <span className="copyright-small  text-light ">
                  During the ongoing pandemic, we recommend you to stay updated
                  with airlines and hoteliers guidelines regarding rescheduling
                  and cancellations.
                </span>
              </div>
            </div>
            <hr />
          </div>

          <div className="gooter-svg">
            <img src={footerBG} className="img-fluid" />
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
