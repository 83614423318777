import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import logo from "../Image/logo.svg";
import flight from "../Image/flight-animate.gif";
import Flight_Not_Found from "../Image/Flight_Not_Found.png";
import { googlrBaseUrl, websiteUrl } from "../static/static";
import moment from "moment";
import Lottie from "react-lottie";
import waiting from "../Animation/vector-vol.json";

const GoogleApiPF = () => {
  const [searchParams] = useSearchParams();
  const Origin = searchParams.get("Origin1");
  const Destination = searchParams.get("Destination1");
  const DepartDate = searchParams.get("DepartureDate1");
  const Price = searchParams.get("DisplayedPrice");
  const Currency = searchParams.get("DisplayedPriceCurrency");
  const navigate = useNavigate();

  const [apiData, setApiData] = useState({});
  const [error, setError] = useState("");
  var t = window.location.href;
  var st = t.split("?")[1];
  // console.log("st:::", st.split("&")[7].split);
  // const DepartDate = st.split("&")[15].split("=")[1];
  // const Origin = st.split("&")[16].split("=")[1];
  // const Destination = st.split("&")[17].split("=")[1];
  const googleApi = async () => {
    var t = window.location.href;
    var st = t.split("?")[1];
    const token = st.split("&")[0].replace("token=", "");
    // console.log(token)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("access-control-allow-origin", "*");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    const googlApi = await fetch(
      googlrBaseUrl + "/google/flights/pf?" + st,
      requestOptions
    );
    const data = await googlApi.json();
    if (data.msg === "Price found successfully") {
      navigate("/bookandreview", {
        state: {
          flight_data: data.data.onWordFlight,
          inBound_data: data.data.returnFlight,
          AllFlight_Data: data.data,
          isPf: true,
        },
      });
    } else if (data.msg === "Price has been updated") {
      navigate("/bookandreview", {
        state: {
          flight_data: data.data.onWordFlight,
          inBound_data: data.data.returnFlight,
          AllFlight_Data: data.data,
          isPf: true,
        },
      });
    } else {
      setError(data.httpStatus);
    }
  };

  useEffect(() => {
    //  getFlightResult()
    googleApi();
    sessionStorage.removeItem("is_Ml");
  }, []);

  const details = {
    loop: true,
    autoplay: true,
    animationData: waiting,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      {error === "SEE_OTHER" ? (
        <div className="height_100vh d-flex align-items-center justify-content-center">
          <div className="">
            <div className="row  col- d-flex align-items-center  justify-content-center bg-white">
              <div className="col-6 text-center">
                <img src={logo} className="err_logo-custom mb-4" width="80%" />
                <div>
                  <img
                    src={Flight_Not_Found}
                    className="flight_not_found_pic"
                  />
                </div>
                <div>
                  <h2>Flight Not Found</h2>
                </div>
                <a href={`${websiteUrl}`}>
                  <div className="go_back_btn">
                    <Button>Go To Home</Button>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="whileload-redirect d-flex align-items-center justify-content-center h-100">
          <div className="container">
            <div className="whileload-inner d-flex align-items-center flex-column justify-content-center">
              <div class="wait mb-4">
                <div class="snippet" data-title=".dot-flashing">
                  <div class="stage d-flex align-items-center justify-content-center">
                    <span className="mr-4 d-flex mt-2 ">
                      <img src={logo} className="" width="150" />
                    </span>
                    {/* <div class="dot-flashing"></div> */}
                  </div>
                </div>
              </div>

              {/* <div className="col-2"><h3>{apiData.Origin1}</h3></div> */}
              {/* <img src={logo} className="" width="150" /> */}
              <div className="col-12 text-center font-600 text-lg xl:text-xl mt-2">
                Wait while we redirect you to <b>travomint.com</b>
              </div>
              <div className="col-12">
                <Lottie options={details} height={250} width={250} />
              </div>

              {/* <div className="col-2"><h3>{apiData.Destination1}</h3></div> */}

              {/* <div className="iata_code arrival_city">{apiData.Destination1}</div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default GoogleApiPF;
