import React from "react";
import logo from "../Image/logo.svg";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import { Button, FormControl } from "react-bootstrap";
import google from "../Image/google_logo.png";
import { Link, useNavigate } from "react-router-dom";
import SideNav from "../Atom/SideNav";
import LocalPlay from "@material-ui/icons/LocalPlay";
import HeaderBg from "../Image/header_bg_1.svg";

import Form from "react-bootstrap/Form";
import Country from "./Country";
import { domain, imgdomain, websiteUrl } from "../static/static";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TopNav = (props) => {
  const navigate = useNavigate();
  let [isOpen, setIsOpen] = useState(false);
  let [isOpen1, setIsOpen1] = useState(false);
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal1() {
    setIsOpen1(false);
  }

  function openModal1() {
    setIsOpen1(true);
  }

  const logInFun = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      user: 0,
      username: email,
      password: password,
      role: "admin",
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const callingapi = await fetch(
      `http://10.10.8.81:6500/agent/login`,
      requestOptions
    );
    const response = await callingapi.json();
    const user = response.data;
    if (user) {
      if (user.isactive === true) {
        navigate("/Hotel");
      } else {
        console.log("Invalid email & password");
      }
    } else {
      console.log("Log in Error");
    }
  };

  return (
    <>
      <nav class="mb-4 navbar navbar-expand-lg navbar-dark bg-unique">
        {/* <i
          class="fas fa-bars fa-2x text-center text-orange-600 font-ad"
          onClick={() => props.setside(true)}
        ></i> */}
        <a href={websiteUrl} className="col-span-4 ml-2">
          <img src={logo} className="" width="150" />
        </a>

        <div className="ml-auto">
          <b className="d-none d-md-inline-block text-dark text-sm align-middle mr-3 font-700">
            For Unpublished Deals
          </b>
          <a
            href={"tel:+91-1204814491"}
            target="_blank"
            className="btn btn-phoneheader"
          >
            <span className="mr-2 d-inline-block concall-img align-middle">
              <i class="fas fa-headset"></i>
            </span>
            <span className="d-inline-block align-middle num">
              +91-1204814491
            </span>
          </a>
        </div>

        {/* <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent-3"
          aria-controls="navbarSupportedContent-3"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button> */}
        {/* <div class="collapse navbar-collapse" id="navbarSupportedContent-3">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item"></li>
          </ul>
          <ul class="navbar-nav ml-auto nav-flex-icons">
            <li class="nav-item">
              <a class="nav-link waves-effect waves-light">
                <i class="fa fa-twitter"></i>
              </a>
            </li>
            <li class="nav-item">
              <Country />
            </li>
            <li class="nav-item dropdown">
              <div className="Login-wrapp">
                <a
                  onClick={openModal}
                  className="px-4 py-2  text-sm Login-button "
                >
                  <i className="fas fa-user"></i>
                  Log In/ Sign Up
                </a>
              </div>
            </li>
          </ul>
        </div> */}
      </nav>
      {/* <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed bg-slate-200 inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

           
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <i class="fas fa-user border-4 bg-blue-500 text-white border-white drop-shadow-xl outline-2 outline-gray-500 p-2 rounded-3xl"></i>
                  <span className="ml-2">Sign In</span>
                </Dialog.Title>
                <div className="mt-6">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check type="checkbox" label="Remember Me" />
                    </Form.Group>
                    <div className="mt-4">
                      <button
                        type="button"
                        className="inline-flex w-1/2 justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        // onClick={closeModal}
                        onClick={() => logInFun()}
                      >
                        LOG IN
                      </button>

                      <a href="#" className="float-right">
                        Forget Password
                      </a>
                    </div>
                    <div className="mt-4">
                      <button className="border-2 border-gray-200 shadow  pt-2 pb-2 pl-20 pr-20 ">
                        <img src={google} className="w-1/5 inline" />{" "}
                        <span className="text-xl"> Sign In With Google</span>
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition> */}

      {/* <Transition appear show={isOpen1} as={Fragment}>
        <Dialog
          as="div"
          className="fixed bg-slate-200 inset-0 z-10 overflow-y-auto"
          onClose={closeModal1}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

         
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <i class="fas fa-user border-4 bg-blue-500 text-white border-white drop-shadow-xl outline-2 outline-gray-500 p-2 rounded-3xl"></i>
                  <span className="ml-2">Sign In</span>
                </Dialog.Title>
                <div className="mt-6">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control type="text" placeholder="Enter Your Name" />
                      <Form.Text className="text-muted">
                        Provide your information.
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        placeholder="Enter Your Email"
                      />
                      <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control
                        type="password"
                        placeholder="Enter Your Password"
                      />
                      <Form.Text className="text-muted">
                        Use Strong Password for More Security.
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check type="checkbox" label="Remember Me" />
                    </Form.Group>
                    <div className="mt-4">
                      <button
                        type="button"
                        className="inline-flex w-1/2 justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={closeModal1}
                      >
                        LOG IN
                      </button>

                      <a href="#" className="float-right">
                        Forget Password
                      </a>
                    </div>
                    <div className="mt-4">
                      <button className="border-2 border-gray-200 shadow  pt-2 pb-2 pl-20 pr-20 ">
                        <img src={google} className="w-1/5 inline" />{" "}
                        <span className="text-xl"> Sign In With Google</span>
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition> */}
    </>
  );
};

export default TopNav;
