import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SelectedData } from "../../Feature/Action";
import SG from "../../Image/SG.png";
import { imgdomain, websiteUrl } from "../../static/static";
const First = (props) => {
  const { flightdata, allflightdata, inBoundData } = props;
  const ConvertMinsToTime = ({ data }) => {
    let hours = Math.floor(data / 60);
    let minutes = data % 60;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}h:${minutes}m`;
  };
  const convertFrom24To12Format = (time24) => {
    const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    const period = +sHours < 12 ? "AM" : "PM";
    const hours = +sHours % 12 || 12;
    return `${hours}:${minutes} ${period}`;
  };
  return (
    <>
      <div className="flight-details shadow-md p-3 my-4 bg-white rounded">
        {/* <h4>
          {" "}
          <i class="fas fa-fighter-jet text-black"></i> &nbsp; Flight Detail{" "}
        </h4> */}
        

        {/* //Shivam Sir */}
        {/* <div className="grid grid-cols-6  mt-2  ">
          <div className="col-span-2">
            <div className="grid grid-cols-3">
              <div className="pt-2 pb-3">
                <img
                  src={SG}
                  className="w-6/12 down rounded-xl inline float-right"
                />
              </div>

              <div className="pl-4 pt-2 col-span-2">
                <span className="text-xl text-black font-sans font-bold">
                  Spicejet
                </span>
                <br />
                <span className="text-xs text-black font-sans font-bold">
                  {" "}
                  I5-764
                </span>
              </div>
            </div>
          </div>
          <div className="col-span-4 px-14">
            <div className="grid grid-cols-4">
              <div className="text-center py-3">
              
                <span className="text-lg text-black font-bold">DEL</span>
              </div>
              <div className="col-span-2">
                <div className="grid grid-cols-9 mt-3 ">
                  <div className="col-span-3 pl-2">
                    <hr className="bg-black opacity-100" />
                  </div>
                  <div className="col-span-3 text-center relative -top-2">
                    <span className="text-xs text-black font-sans">2h 15m</span>
                    <br />
                    <span className="text-xs text-black font-sans">
                      Non-Stop
                    </span>
                  </div>
                  <div className="col-span-3 pr-2">
                    <hr className="bg-black opacity-100" />
                  </div>
                </div>
              </div>
              <div className="text-center py-3">
                <span className="text-lg text-black font-bold">03:45 PM </span>
                <span className="text-lg text-black font-bold">DEL</span>
              </div>
            </div>
          </div>
        </div> */}

        {/* // Sachin */}
 
 
        <div>
          <div>
            <h5>Departure</h5>
          </div>
          {flightdata.outBound.map((item, index) => {
            return (
              <div className="mt-2 row">
                <div className="col-md-12 col-12 d-flex airline-name-i">
                  <img
                    src={`${websiteUrl}/resources/images/airline-logo/${item.airline}.png`}
                  />

                  <div className=" codeName">
                    <span className="text-xl text-black font-sans font-bold">
                      {item.airlineName}
                    </span>
                    -
                    <span className="text-xs  text-black font-sans font-bold">
                      {" "}
                      {item.flightNo}
                    </span>
                  </div>
                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-4">
                  <div className="border-left-de p-0 d-flex w-100 align-items-center justify-between">
                    <div className="col-xl-4 col-lg-4 p-0 col-md-4 col-5  py-3">
                      <span className="lg:text-lg text-[10px] text-black font-bold">
                        {moment(item.depDate.split("T")[0]).format(
                          "ddd, DD-MMM"
                        )}
                        <span className="text-travomint font-700 ml-2">
                          {convertFrom24To12Format(
                            item.depDate.split("T")[1].substring(0, 5)
                          )}{" "}
                        </span>
                      </span>
                      <br />

                      <span className="text-lg text-black font-700">
                        <strong>{item.fromAirport}</strong>
                      </span>
                    </div>
                    <div className="col-xl-4 col-lg-4 p-0 col-md-4 col-2 col-span-2">
                      <div className=" ">
                        <div className="text-center">
                          <span className="line-dooted"></span>
                          <span className="text-xs text-black font-sans layover-time">
                            <ConvertMinsToTime data={item.eft} />
                          </span>
                          <br />
                          {/* <span className="text-xs text-black font-sans">
                            {flightdata.outBound.length === 1
                              ? "Non-Stop"
                              : flightdata.outBound.length === 2
                              ? "one-Stop"
                              : flightdata.outBound.length === 3
                              ? "two-stop"
                              : null}
                          </span> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-3 p-0 col-md-4 col-5  text-right py-3">
                      <span className="lg:text-lg text-[10px] text-black font-bold">
                        {moment(item.reachDate.split("T")[0]).format(
                          "ddd, DD-MMM"
                        )}
                        <span className="ml-2 text-travomint font-700">
                          {convertFrom24To12Format(
                            item.reachDate.split("T")[1].substring(0, 5)
                          )}{" "}
                        </span>
                      </span>
                      <br />
                      <span className="text-lg text-black font-700">
                        <strong>{item.toAirport}</strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {allflightdata.tripType === 2 ? (
          <>
            {/* int */}
            {allflightdata.domestic !== true ? (
              <div>
                <div>
                  <h5>Return</h5>
                </div>
                {flightdata.inBound.map((item, index) => {
                  return (
                    <div className="mt-2 row">
                     <div className="col-md-12 col-12 d-flex airline-name-i">
                  <img
                    src={`${websiteUrl}/resources/images/airline-logo/${item.airline}.png`}
                  />

                  <div className=" codeName">
                    <span className="text-xl text-black font-sans font-bold">
                      {item.airlineName}
                    </span>
                    -
                    <span className="text-xs  text-black font-sans font-bold">
                      {" "}
                      {item.flightNo}
                    </span>
                  </div>
                </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-4">
                      <div className="border-left-de d-flex w-100 align-items-center justify-between">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-5  py-3 p-0">
                      <span className="lg:text-lg text-[10px] text-black font-bold">
                        {moment(item.depDate.split("T")[0]).format(
                          "ddd, DD-MMM"
                        )}
                        <span className="text-travomint font-700 ml-2">
                          {convertFrom24To12Format(
                            item.depDate.split("T")[1].substring(0, 5)
                          )}{" "}
                        </span>
                      </span>
                      <br />

                      <span className="text-lg text-black font-700">
                        <strong>{item.fromAirport}</strong>
                      </span>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-2 col-span-2 p-0">
                      <div className=" ">
                        <div className="text-center">
                          <span className="line-dooted"></span>
                          <span className="text-xs text-black font-sans layover-time">
                            <ConvertMinsToTime data={item.eft} />
                          </span>
                          <br />
                          {/* <span className="text-xs text-black font-sans">
                            {flightdata.outBound.length === 1
                              ? "Non-Stop"
                              : flightdata.outBound.length === 2
                              ? "one-Stop"
                              : flightdata.outBound.length === 3
                              ? "two-stop"
                              : null}
                          </span> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-3 col-md-4 col-5  text-right py-3 p-0">
                      <span className="lg:text-lg text-[10px] text-black font-bold">
                        {moment(item.reachDate.split("T")[0]).format(
                          "ddd, DD-MMM"
                        )}
                        <span className="ml-2 text-travomint font-700">
                          {convertFrom24To12Format(
                            item.reachDate.split("T")[1].substring(0, 5)
                          )}{" "}
                        </span>
                      </span>
                      <br />
                      <span className="text-lg text-black font-700">
                        <strong>{item.toAirport}</strong>
                      </span>
                    </div>
                  </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                <div>
                  <h5>Return</h5>
                </div>
                {inBoundData.inBound.map((item, index) => {
                  return (
                    <div className="mt-2 row">
                    <div className="col-md-12 col-12 d-flex airline-name-i">
                 <img
                   src={`${websiteUrl}/resources/images/airline-logo/${item.airline}.png`}
                 />

                 <div className=" codeName">
                   <span className="text-xl text-black font-sans font-bold">
                     {item.airlineName}
                   </span>
                   -
                   <span className="text-xs  text-black font-sans font-bold">
                     {" "}
                     {item.flightNo}
                   </span>
                 </div>
               </div>

                     <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-4">
                     <div className="border-left-de d-flex w-100 align-items-center justify-between">
                   <div className="col-xl-4 col-lg-4 col-md-4 col-5  py-3 p-0">
                     <span className="lg:text-lg text-[10px] text-black font-bold">
                       {moment(item.depDate.split("T")[0]).format(
                         "ddd, DD-MMM"
                       )}
                       <span className="text-travomint font-700 ml-2">
                         {convertFrom24To12Format(
                           item.depDate.split("T")[1].substring(0, 5)
                         )}{" "}
                       </span>
                     </span>
                     <br />

                     <span className="text-lg text-black font-700">
                       <strong>{item.fromAirport}</strong>
                     </span>
                   </div>
                   <div className="col-xl-4 col-lg-4 col-md-4 col-2 col-span-2 p-0">
                     <div className=" ">
                       <div className="text-center">
                         <span className="line-dooted"></span>
                         <span className="text-xs text-black font-sans layover-time">
                           <ConvertMinsToTime data={item.eft} />
                         </span>
                         <br />
                         {/* <span className="text-xs text-black font-sans">
                           {flightdata.outBound.length === 1
                             ? "Non-Stop"
                             : flightdata.outBound.length === 2
                             ? "one-Stop"
                             : flightdata.outBound.length === 3
                             ? "two-stop"
                             : null}
                         </span> */}
                       </div>
                     </div>
                   </div>
                   <div className="col-xl-4 col-lg-3 col-md-4 col-5  text-right py-3 p-0">
                     <span className="lg:text-lg text-[10px] text-black font-bold">
                       {moment(item.reachDate.split("T")[0]).format(
                         "ddd, DD-MMM"
                       )}
                       <span className="ml-2 text-travomint font-700">
                         {convertFrom24To12Format(
                           item.reachDate.split("T")[1].substring(0, 5)
                         )}{" "}
                       </span>
                     </span>
                     <br />
                     <span className="text-lg text-black font-700">
                       <strong>{item.toAirport}</strong>
                     </span>
                   </div>
                 </div>
                     </div>
                   </div>
                  );
                })}
              </div>
            )}
          </>
        ) : null}
      </div>

      <div className="grid grid-cols-3 up rounded-2xl my-3 mx-2 info-head-payment ">
        <div className="payment-info-head">
          <h4>
            <i classname=""></i>Good to Know
          </h4>
          <p>Information you should know </p>
        </div>

        <ul>
          <li>
            Airline Cancellation Fee is Rs 3500 per passenger for your selected
            flight on the sector.
          </li>
          <li>
            Certify your health status through the Aarogya Setu app or the
            self-declaration form at the airport
          </li>
          <li>Remember to web check-in before arriving at the airport</li>
          <li>Face masks are compulsory</li>
        </ul>
      </div>
    </>
  );
};
export default First;
